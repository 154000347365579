import React, { forwardRef, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { actions as AchievementsActions } from '../../redux/ducks/achievements';
import { imageParser } from '../../utils/utils';
import { QrMenu } from '../../components/QrMenu';
import QrContent from '../../components/QrContent';
import {
    AppBar, CardActionArea, Grid, Container, Typography, IconButton,
    Toolbar, useMediaQuery, Slide
} from '@material-ui/core';
import DialogBadgeDetails from './components/DialogBadgeDetails';
import EmptyContent from '../../components/EmptyContent';
import ErrorContent from '../../components/ErrorContent';
import QrImage from '../../components/QrImage';
import { Icon } from '../../components/Images/Images';
import { LoadingContent } from '../../components/Loading';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AchievementsIndex(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const list = useSelector(state => state.achievements.list);
    const isScreenUpMd = useMediaQuery(theme => theme.breakpoints.up('md'));
    const [themeMode] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);
    const [open, setOpen] = useState(false);
    const [didMount, setDidMount] = useState(false);
    const [data, setData] = useState([]);
    let bottomMenuItem = config.layout.menu.nav_bottom_order.includes('MENU_ACHIEVEMENTS');

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            dispatch(AchievementsActions.getList({}));
        }

        return () => {
            dispatch(AchievementsActions.reset());
            setDidMount(false);
        }
    }, [didMount, dispatch]);

    const QrAppBar = () => {
        return (
            <AppBar className={`appbar appbar-${bottomMenuItem || isScreenUpMd ? "main" : "default"}`} position={`${bottomMenuItem || isScreenUpMd ? "static" : "sticky"}`} color="primary">
                <Toolbar>
                    {
                        config.layout.navbar && !bottomMenuItem && !isScreenUpMd ?
                            <IconButton
                                color="inherit"
                                aria-label="back"
                                edge="start"
                                onClick={() => history.goBack()}
                            >
                                <Icon ic="back" />
                            </IconButton>
                            :
                            null
                    }
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get('ACHIEVEMENTS_TITLE')}
                        </Typography>
                        {
                            bottomMenuItem || isScreenUpMd ?
                                <div className="w-100 d-flex overflow-hidden">
                                    <Typography variant="body2" noWrap>
                                        {intl.get("ACHIEVEMENTS_SUBTITLE")}
                                    </Typography>
                                </div>
                                : null
                        }
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    const handleOpen = (data) => {
        setOpen(true);
        setData(data);
    };

    const handleClose = () => {
        setOpen(false);
        setData([]);
    };

    return (
        <div id="qr-root" className={themeMode === "DARK" ? "__dark" : "__light"}>
            {
                auth.authenticated &&
                <div id="qr-wrap">
                    <QrMenu />
                    <div id="qr-content">
                        <QrContent id="achievements-list" bottomNavigation={bottomMenuItem} hideSidebar>
                            {
                                config.layout.navbar ?
                                    <QrAppBar />
                                    : null
                            }
                            <div className={`${bottomMenuItem ? "page-bottom-menu" : ""}`}>
                                {
                                    list && list.load === "finished" ?
                                        !list.error ?
                                            list.data.length !== 0 ?
                                                <Container maxWidth="lg" className="pt-2 pb-4">
                                                    <div className="subheader-content justify-content-between">
                                                        <Typography variant="subtitle2" component="h3">
                                                            {intl.get("ACHIEVEMENTS_LIST_SUBHEADER")}
                                                        </Typography>
                                                        <Typography variant="subtitle2" component="p">
                                                            {`${list.data.total_user} / ${list.data.total}`}
                                                        </Typography>
                                                    </div>
                                                    <Grid container spacing={2}>
                                                        {
                                                            list.data.badges.map((item, index) => {
                                                                return (
                                                                    <Grid key={index} item xs={4} sm={3} lg={2} align="center">
                                                                        <CardActionArea aria-hidden="false" className={`badge-content${item.redeem_available === "Y" ? " __redeemed" : ""}`} onClick={() => handleOpen(item)}>
                                                                            <QrImage
                                                                                src={imageParser(item.image)}
                                                                                effect="blur"
                                                                                className="badge-image"
                                                                                alt="Badge"
                                                                            />
                                                                            <Typography variant="body1" component="h4" className="badge-name">
                                                                                {item.name}
                                                                            </Typography>
                                                                        </CardActionArea>
                                                                    </Grid>
                                                                )
                                                            })
                                                        }
                                                    </Grid>
                                                </Container>
                                                :
                                                <EmptyContent icon={<Icon ic="shopping_bag_filled" className="icon-xxl" />} text={intl.get("EMPTY_MY_REDEEMS")} />
                                            :
                                            <ErrorContent />
                                        :
                                        <LoadingContent />
                                }
                            </div>
                        </QrContent>
                    </div>
                    <DialogBadgeDetails
                        details
                        open={open}
                        close={() => handleClose()}
                        transition={Transition}
                        data={data}
                    />
                </div>
            }
        </div >
    )
};
