import React from 'react';
import intl from 'react-intl-universal';
import { Grid, Typography } from '@material-ui/core';
import FeedbackBox from '../components/FeedbackBox';
import QrVideoPlayer from '../../../../components/QrVideoPlayer';

const VideoAnswer = ({ data }) => {
    return (
        // Resposta Correta
        data.correct === "Y" ?
            <Grid container justify="center">
                <Grid item xs={12}>
                    <FeedbackBox
                        correct
                        title={intl.get("GAME_SOLUTION_ANSWER_SELECTED_SUBTITLE")}
                    >
                        <Grid item xs={12} align="center">
                            <div className="embed-responsive embed-responsive-16by9 rounded-lg">
                                <QrVideoPlayer link={data.user_answer_media.source} />
                            </div>
                        </Grid>
                    </FeedbackBox>
                </Grid>
            </Grid>
            :
            // Resposta Incorreta
            data.correct === "N" ?
                <Grid container justify="center">
                    <Grid item xs={12}>
                        <FeedbackBox
                            correct={false}
                            title={intl.get("GAME_SOLUTION_ANSWER_SELECTED_SUBTITLE")}
                        >
                            <Grid item xs={12} align="center">
                                <div className="embed-responsive embed-responsive-16by9 rounded-lg">
                                    <QrVideoPlayer link={data.user_answer_media.source} />
                                </div>
                            </Grid>
                        </FeedbackBox>
                    </Grid>
                </Grid>
                :
                // Aguardando Correção
                data.user_answer ?
                    <Grid container justify="center">
                        <Grid item xs={12}>
                            <FeedbackBox
                                disabled
                                title={intl.get("GAME_SOLUTION_ANSWER_SENT_SUBTITLE")}
                            >
                                <Grid item xs={12} align="center">
                                    <div className="embed-responsive embed-responsive-16by9 rounded-lg">
                                        <QrVideoPlayer link={data.user_answer_media.source} />
                                    </div>
                                </Grid>
                            </FeedbackBox>
                        </Grid>
                    </Grid>
                    :
                    // Sem Resposta
                    <Grid container justify="center">
                        <Grid item xs={12}>
                            <FeedbackBox
                                disabled
                            >
                                <Grid item xs={12}>
                                    <Typography variant="body1" component="div">
                                        {intl.get("EMPTY_ANSWER")}
                                    </Typography>
                                </Grid>
                            </FeedbackBox>
                        </Grid>
                    </Grid>
    )
}

export default VideoAnswer;