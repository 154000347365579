const projects = {
    QRANIO: {
        app: {
            name: 'Qranio',
            name_project: 'QRANIO',
            description: 'Aplicativo Qranio',
            favicon: '/favicon/qranio/favicon.ico',
            code: '',
        },
        logos: {
            app_icon: require('./dist/img/projects/qranio/logos/LogoAppIcon'),
            main: {
                color: require('./dist/img/projects/qranio/logos/LogoMainColor'),
                clean: require('./dist/img/projects/qranio/logos/LogoMainClean'),
            },
            group: {
                color: require('./dist/img/svgr/LogoQranioColor'),
                clean: require('./dist/img/svgr/LogoQranioClean'),
            },
            badge: {
                color: require('./dist/img/projects/qranio/logos/LogoBadgeColor'),
                clean: require('./dist/img/projects/qranio/logos/LogoBadgeClean'),
            },
            qranio: {
                color: require('./dist/img/svgr/LogoQranioColor'),
                clean: require('./dist/img/svgr/LogoQranioClean'),
            },
        },
        firebase: {
            config: {
                apiKey: 'AIzaSyBGK7CUGzk4sPMbHAe-ezFBa57qNYmRWLg',
                authDomain: 'apps-qranio.firebaseapp.com',
                databaseURL: 'https://apps-qranio.firebaseio.com',
                projectId: 'apps-qranio',
                storageBucket: 'apps-qranio.appspot.com',
                messagingSenderId: '618857072460',
                appId: '1:618857072460:web:67a90dcac6bceabf1f31e9',
                measurementId: 'G-S1YKL8TZDF'
            },
            vapid: {
                vapidKey: 'BKagOny0KF_2pCJQ3mmoL0ewzQ8rZu67a90dcac6bceabf1f31e9'
            }
        },
        captcha: {
            type: 'invisible', // [checkbox / invisible]
            key: '6Lc9cMcZAAAAAEtugnHlNGaIC58TAh73SqVxlDtU',
            invisible_key: '6LcS_MYZAAAAAP9XzJ9khXL1m-Rk1dpvwDu55Uou',
        },
        login_method: {
            qrlogin: {
                enabled: false,
                type: 'email' // [email / username]
            },
            ad_azure: {
                enabled: false,
                scopes: ["profile", "User.Read"],
                style: 'button', // [button / link]
            },
            saml2: {
                enabled: false,
                style: 'button', // [button / link]
            },
            email: {
                login: {
                    enabled: true,
                    style: {
                        small_screen: 'button', // [form / button / link]
                        large_screen: 'form', // [form / button / link]
                    },
                    show_access_work_confirmation: false
                },
                signup: {
                    enabled: true,
                    display_button: {
                        intro: {
                            enabled: true,
                            style: {
                                small_screen: 'button', // [button / link]
                                large_screen: 'link', // [button / link]
                            }
                        },
                        login: {
                            enabled: true,
                            style: 'link', // [button / link]
                        }
                    },
                    have_country: true,
                    country_default: 'BR',
                    have_enterprise: false
                },
                forgot_password: {
                    enabled: true
                }
            },
            username: {
                login: {
                    enabled: false,
                    style: {
                        small_screen: 'button', // [form / button / link]
                        large_screen: 'form', // [form / button / link]
                    },
                    label: 'CPF', // [LOGIN / CPF / DRT]
                    show_access_work_confirmation: false
                },
                signup: {
                    enabled: false,
                    display_button: {
                        intro: {
                            enabled: true,
                            style: {
                                small_screen: 'button', // [button / link]
                                large_screen: 'link', // [button / link]
                            }
                        },
                        login: {
                            enabled: true,
                            style: 'link', // [button / link]
                        }
                    },
                    have_country: true,
                    country_default: 'BR',
                    have_enterprise: false
                },
                forgot_password: {
                    enabled: true
                }
            },
            password: {
                complex: false,
                min: 8
            },
            first_access: {
                enabled: false,
                code_verify: false,
                questions: false,
                number_questions: 5
            },
            complete_data: {
                form: {
                    cell_number: {
                        enabled: false,
                        required: false
                    },
                    city: {
                        enabled: false,
                        required: false
                    },
                    country: {
                        enabled: true,
                        required: false
                    },
                    email: {
                        enabled: false,
                        required: false,
                        domain: ["general"] // Array[general / domains]
                    },
                    birthdate: {
                        enabled: true,
                        required: false
                    },
                    state: {
                        enabled: false,
                        required: false
                    },
                    brand: {
                        enabled: false,
                        required: false
                    },
                    role: {
                        enabled: false,
                        required: false
                    },
                    role_family: {
                        enabled: false,
                        required: false
                    },
                    role_group: {
                        enabled: false,
                        required: false
                    },
                    position: {
                        enabled: false,
                        required: false
                    },
                    regional: {
                        enabled: false,
                        required: false
                    },
                    subarea: {
                        enabled: false,
                        required: false
                    },
                    board: {
                        enabled: false,
                        required: false
                    },
                    executive_board: {
                        enabled: false,
                        required: false
                    },
                    managing: {
                        enabled: false,
                        required: false
                    },
                    cost_center: {
                        enabled: false,
                        required: false
                    },
                    enterprise: {
                        enabled: false,
                        required: false
                    },
                }
            },
            have_alternative_code: false
        },
        intro: {
            logo: {
                small_screen: {
                    type: 'main-color', // [app-icon, main-color, main-clean, group-color, group-clean, badge-color, badge-clean, qranio-color, qranio-clean]
                    size: 64
                },
                large_screen: {
                    type: 'main-color',
                    size: 80
                },
                footer: {
                    qranio: {
                        display: true,
                        type: 'qranio-color',
                        size: 24
                    },
                    group: {
                        display: false,
                        type: 'group-color',
                        size: 24
                    }
                }
            },
            type: 1, // [1 (com slider) / 2 (sem slider)]
            large_split_screen: false,
            vector_images: true,
            slider: [
                {
                    type: 'Course',
                    enabled: true,
                    text: 'Aqui você aprende de uma maneira divertida',
                },
                {
                    type: 'Duel',
                    enabled: true,
                    text: 'Tenha a liberdade de duelar com seus amigos',
                },
                {
                    type: 'Onboarding',
                    enabled: true,
                    text: 'Ganhe reconhecimento',
                },
                {
                    type: 'Ranking',
                    enabled: true,
                    text: 'Entre para o topo do ranking entre nossos usuários',
                },
                {
                    type: 'Prizes',
                    enabled: true,
                    text: 'Troque prêmios através de moedas adquiridas com seu conhecimento',
                },
                {
                    type: 'Feeds',
                    enabled: true,
                    text: 'Comente e interaja através de diversos conteúdos',
                },
                {
                    type: 'Scorm',
                    enabled: true,
                    text: 'Acompanhe todo o seu desenvolvimento',
                },
            ],
            show_popup: true,
            show_title: true,
            show_footer: true
        },
        layout: {
            theme: 'DARK',
            input_variant: 'outlined', // [outlined / filled / standard]
            points: {
                neuros: true,
                qis: true
            },
            menu: {
                expanded: false,
                nav_order: ["MENU_HOME", "MENU_FEEDS", "MENU_LEARN", "MENU_PRIZES", "MENU_RANKING", "MENU_NOTIFICATIONS", "MENU_ACHIEVEMENTS",
                    "MENU_LIBRARY", "MENU_MY_REDEEMS", "MENU_CODE_REDEEM", "MENU_MY_HISTORY", "MENU_MANAGER_PANEL", "MENU_FAQ", "MENU_SETTINGS"],
                nav_bottom_order: ["MENU_HOME", "MENU_FEEDS", "MENU_LEARN", "MENU_MY_HISTORY", "MENU_MORE"],
                logout_button: true,
                logo_footer: false
            },
            navbar: true,
            sidebar: {
                expanded: true
            },
            files: {
                download: false
            },
            videos: {
                download: false,
                picture_in_picture: true
            }
        },
        home: {
            empty_modules_visible: false,
            sidebar: true,
            sections: {
                highlight: {
                    enabled: true,
                    show_mobile: {
                        title: true,
                        text: true
                    }
                },
                knowmore: {
                    image_type: 'icon', // [play / image / icon]
                    image: '',
                    section_color: 'section-home',
                    icon: 'info_popup',
                    icon_color: 'white',
                    enabled: true
                },
                trail: {
                    image_type: 'play', // [play / image / icon]
                    image: 'trail',
                    section_color: 'section-play-trail',
                    icon: 'play_trail',
                    icon_color: 'white',
                    categories: 'courses', // [themes / courses]
                    mobile_grid: 'inline' // [inline / grid]
                },
                scorm: {
                    image_type: 'play', // [play / image / icon]
                    image: 'scorm',
                    section_color: 'section-play-scorm',
                    icon: 'play_scorm',
                    icon_color: 'white',
                    categories: 'courses', // [themes / courses]
                    mobile_grid: 'inline' // [inline / grid]
                },
                free: {
                    image_type: 'play', // [play / image / icon]
                    image: 'free',
                    section_color: 'section-play-free',
                    icon: 'play_free',
                    icon_color: 'white',
                    mobile_grid: 'inline' // [inline / grid]
                },
                classroom: {
                    image_type: 'play', // [play / image / icon]
                    image: 'classroom',
                    section_color: 'section-play-classroom',
                    icon: 'play_classroom',
                    icon_color: 'white'
                },
                exam: {
                    image_type: 'play', // [play / image / icon]
                    image: 'exam',
                    section_color: 'section-play-exam',
                    icon: 'play_exam',
                    icon_color: 'white'
                },
            },
            order: ["HIGHLIGHTS", "KNOWMORE", "PERIODIC_QUESTION", "TRAIL", "FREE", "EXAM", "CLASSROOM_COURSE", "SCORM"],
        },
        feeds: {
            enabled: true,
            cards: {
                date: true
            }
        },
        learn: {
            enabled: true,
            answer_double_click: true,
            order: ["PERIODIC_QUESTION", "FREE", "TRAIL", "EXAM", "CLASSROOM_COURSE", "SCORM", "VIRTUAL_LIBRARY"],
            trail: {
                list: 'themes', // [themes / courses]
                categories: {
                    grid: {
                        style: 'default', // [default / card]
                        size: 'large', // [small / large]
                        mobile: 'default', // [default / full]
                    },
                    stars: true,
                    steps: false,
                    workload: false,
                    progress: {
                        enabled: false,
                        type: 'bar', // [bar / circular]
                        label: true,
                        position: 'center' // [left / center / right]
                    }
                },
                vector_images: true,
                chars: true,
                type: 'stars', // [stars / step]
                workload: true,
                best_view: true,
                scorm_open_alert: true,
                rating_comments: true,
                completed_message: false,
                stage: {
                    medal: true,
                    shadow: true,
                    stars: true,
                    stages: false,
                    stage_type: true
                },
                unified_stages: {
                    display: true,
                    expanded: 'auto' // [true, false, auto]
                },
                result_images: {
                    win: {
                        female1: require('./dist/img/banner/img_background_result_win_1_1.jpg'),
                        female2: require('./dist/img/banner/img_background_result_win_1_2.jpg'),
                        male1: require('./dist/img/banner/img_background_result_win_2_1.jpg'),
                        male2: require('./dist/img/banner/img_background_result_win_2_2.jpg')
                    },
                    lose: require('./dist/img/banner/img_background_result_lose.jpg')
                }
            },
            scorm: {
                list: 'themes', // [themes / courses]
                categories: {
                    grid: {
                        style: 'default', // [default / card]
                        size: 'small', // [small / large]
                        mobile: 'default', // [default / full]
                    },
                    progress: {
                        enabled: true,
                        type: 'circular', // [bar / circular]
                        label: true,
                        position: 'center' // [left / center / right]
                    }
                },
            },
            exam: {
                navigation: 'top', // [bottom / top]
                score: true
            },
            free: {
                categories: {
                    grid: {
                        style: 'default', // [default / card]
                        size: 'small', // [small / large]
                        mobile: 'default', // [default / full]
                    },
                },
            }
        },
        ranking: {
            flag: {
                enabled: true,
                type: "country", // [country / states]
            },
            filter: true,
            max_positions: 0 // [infinite = 0 / limit number (min = 3)]
        },
        library: {
            have_category: true
        },
        manager: {
            head_cards: {
                scorm: true,
                classroom: true,
                trail: true,
            },
            table_team_employees: true,
            table_team_activities: true,
            table_all_users: true
        },
        my_history: {
            enabled: true,
            head_cards: {
                last_login: true,
                ranking: true,
                courses_obligatory: true,
                courses_completed: true,
            },
            my_activities: {
                enabled: true,
                type_course: true,
                type: true,
                date_start: true,
                date_finish: true,
                workload: true,
                certificate_validity: true,
                do_until: true,
                obligatory: true
            }
        },
        faq: {
            expanded: true
        },
        settings: {
            edit_profile: {
                avatar: {
                    enabled: true
                },
                first_name: {
                    enabled: true,
                    required: true
                },
                last_name: {
                    enabled: true,
                    required: true
                }
            }
        }
    },

    MEUAPP: {
        app: {
            name: 'MeuApp',
            name_project: 'MEUAPP',
            description: 'Aplicativo',
            favicon: '/favicon/meuapp/favicon.ico',
            code: '_p0',
        },
        logos: {
            app_icon: require('./dist/img/projects/meuapp/logos/LogoAppIcon'),
            main: {
                color: require('./dist/img/projects/meuapp/logos/LogoMainColor'),
                clean: require('./dist/img/projects/meuapp/logos/LogoMainClean'),
            },
            group: {
                color: require('./dist/img/svgr/LogoQranioColor'),
                clean: require('./dist/img/svgr/LogoQranioClean'),
            },
            badge: {
                color: require('./dist/img/projects/meuapp/logos/LogoBadgeColor'),
                clean: require('./dist/img/projects/meuapp/logos/LogoBadgeClean'),
            },
            qranio: {
                color: require('./dist/img/svgr/LogoQranioColor'),
                clean: require('./dist/img/svgr/LogoQranioClean'),
            },
        },
        firebase: {
            config: {
                apiKey: 'AIzaSyBUJv-10-7tiD4uLUU8zM5Z_TTBB4R4RUo',
                authDomain: 'qr-app-meu-app.firebaseapp.com',
                databaseURL: 'https://apps-qranio.firebaseio.com',
                projectId: 'qr-app-meu-app',
                storageBucket: 'qr-app-meu-app.appspot.com',
                messagingSenderId: '164380637859',
                appId: '1:164380637859:web:9b9205087a7b0d05a79d16',
                measurementId: 'G-GCXD9RRFKM'
            },
            vapid: {
                vapidKey: 'BJiUAtBl6G8UWZI3NmkamSxQQHvoq4Yu9iuvBtbo4CFadJ8bJuLnmSZkvfDKKcmfK5i_NDbIzYJ-3e6W5WlOwc8'
            }
        },
        captcha: {
            type: 'invisible', // [checkbox / invisible]
            key: '6LeH-s8oAAAAABN4Yq1PLYA87syi27PXv8Psbp-J',
            invisible_key: '6Lc5988oAAAAAGm5p77fImhspyPQpbP2jxkGo-wK',
        },
        login_method: {
            qrlogin: {
                enabled: true,
                type: 'email' // [email / username]
            },
            ad_azure: {
                enabled: true,
                scopes: ["profile", "User.Read"],
                style: 'button', // [button / link]
            },
            saml2: {
                enabled: true,
                style: 'button', // [button / link]
            },
            email: {
                login: {
                    enabled: true,
                    style: {
                        small_screen: 'button', // [form / button / link]
                        large_screen: 'form', // [form / button / link]
                    },
                    show_access_work_confirmation: false
                },
                signup: {
                    enabled: true,
                    display_button: {
                        intro: {
                            enabled: true,
                            style: {
                                small_screen: 'button', // [button / link]
                                large_screen: 'link', // [button / link]
                            }
                        },
                        login: {
                            enabled: true,
                            style: 'link', // [button / link]
                        }
                    },
                    have_country: true,
                    country_default: 'BR',
                    have_enterprise: false
                },
                forgot_password: {
                    enabled: true
                }
            },
            username: {
                login: {
                    enabled: false,
                    style: {
                        small_screen: 'button', // [form / button / link]
                        large_screen: 'form', // [form / button / link]
                    },
                    label: 'CPF', // [LOGIN / CPF / DRT]
                    show_access_work_confirmation: false
                },
                signup: {
                    enabled: false,
                    display_button: {
                        intro: {
                            enabled: true,
                            style: {
                                small_screen: 'button', // [button / link]
                                large_screen: 'link', // [button / link]
                            }
                        },
                        login: {
                            enabled: true,
                            style: 'link', // [button / link]
                        }
                    },
                    have_country: true,
                    country_default: 'BR',
                    have_enterprise: false
                },
                forgot_password: {
                    enabled: true
                }
            },
            password: {
                complex: false,
                min: 8
            },
            first_access: {
                enabled: false,
                code_verify: false,
                questions: false,
                number_questions: 5
            },
            complete_data: {
                form: {
                    cell_number: {
                        enabled: true,
                        required: false
                    },
                    city: {
                        enabled: true,
                        required: false
                    },
                    country: {
                        enabled: true,
                        required: false
                    },
                    email: {
                        enabled: true,
                        required: false,
                        domain: ["general"] // Array[general / domains]
                    },
                    birthdate: {
                        enabled: true,
                        required: true
                    },
                    state: {
                        enabled: true,
                        required: false
                    },
                    brand: {
                        enabled: true,
                        required: true
                    },
                    role: {
                        enabled: true,
                        required: true
                    },
                    role_family: {
                        enabled: true,
                        required: true
                    },
                    role_group: {
                        enabled: true,
                        required: true
                    },
                    position: {
                        enabled: true,
                        required: true
                    },
                    regional: {
                        enabled: true,
                        required: true
                    },
                    subarea: {
                        enabled: true,
                        required: true
                    },
                    board: {
                        enabled: true,
                        required: true
                    },
                    executive_board: {
                        enabled: true,
                        required: true
                    },
                    managing: {
                        enabled: true,
                        required: true
                    },
                    cost_center: {
                        enabled: true,
                        required: true
                    },
                    enterprise: {
                        enabled: true,
                        required: true
                    },
                }
            },
            have_alternative_code: false
        },
        intro: {
            logo: {
                small_screen: {
                    type: 'main-clean', // [app-icon, main-color, main-clean, group-color, group-clean, badge-color, badge-clean, qranio-color, qranio-clean]
                    size: 64
                },
                large_screen: {
                    type: 'main-color',
                    size: 80
                },
                footer: {
                    qranio: {
                        display: true,
                        type: 'qranio-color',
                        size: 24
                    },
                    group: {
                        display: false,
                        type: 'group-color',
                        size: 24
                    }
                }
            },
            type: 1, // [1 (com slider) / 2 (sem slider)]
            large_split_screen: false,
            vector_images: true,
            slider: [
                {
                    type: 'Course',
                    enabled: true,
                    text: 'Aqui você aprende de uma maneira divertida',
                },
                {
                    type: 'Duel',
                    enabled: true,
                    text: 'Tenha a liberdade de duelar com seus amigos',
                },
                {
                    type: 'Onboarding',
                    enabled: true,
                    text: 'Ganhe reconhecimento',
                },
                {
                    type: 'Ranking',
                    enabled: true,
                    text: 'Entre para o topo do ranking entre nossos usuários',
                },
                {
                    type: 'Prizes',
                    enabled: true,
                    text: 'Troque prêmios através de moedas adquiridas com seu conhecimento',
                },
                {
                    type: 'Feeds',
                    enabled: true,
                    text: 'Comente e interaja através de diversos conteúdos',
                },
                {
                    type: 'Scorm',
                    enabled: true,
                    text: 'Acompanhe todo o seu desenvolvimento',
                },
            ],
            show_popup: true,
            show_title: true,
            show_footer: true
        },
        layout: {
            theme: 'DARK',
            input_variant: 'outlined', // [outlined / filled / standard]
            char: {
                gender: 'random', // [female / male / random]
                color: 'random', // [black / white / random]
            },
            points: {
                neuros: true,
                qis: true
            },
            menu: {
                expanded: false,
                nav_order: ["MENU_HOME", "MENU_FEEDS", "MENU_LEARN", "MENU_PRIZES", "MENU_RANKING", "MENU_NOTIFICATIONS", "MENU_LIBRARY",
                    "MENU_MY_REDEEMS", "MENU_CODE_REDEEM", "MENU_MY_HISTORY", "MENU_MANAGER_PANEL", "MENU_FAQ", "MENU_SETTINGS"],
                nav_bottom_order: ["MENU_HOME", "MENU_FEEDS", "MENU_LEARN", "MENU_MY_HISTORY", "MENU_MORE"],
                logout_button: true,
                logo_footer: false
            },
            navbar: true,
            sidebar: {
                expanded: true
            },
            files: {
                download: false
            },
            videos: {
                download: false,
                picture_in_picture: true
            }
        },
        home: {
            empty_modules_visible: true,
            sidebar: true,
            sections: {
                highlight: {
                    enabled: true,
                    show_mobile: {
                        title: true,
                        text: true
                    }
                },
                knowmore: {
                    image_type: 'icon', // [play / image / icon]
                    image: '',
                    section_color: 'section-home',
                    icon: 'info_popup',
                    icon_color: 'white',
                    enabled: true
                },
                trail: {
                    image_type: 'play', // [play / image / icon]
                    image: 'trail',
                    section_color: 'section-play-trail',
                    icon: 'play_trail',
                    icon_color: 'white',
                    categories: 'courses', // [themes / courses]
                    mobile_grid: 'inline' // [inline / grid]
                },
                scorm: {
                    image_type: 'play', // [play / image / icon]
                    image: 'scorm',
                    section_color: 'section-play-scorm',
                    icon: 'play_scorm',
                    icon_color: 'white',
                    categories: 'courses', // [themes / courses]
                    mobile_grid: 'inline' // [inline / grid]
                },
                free: {
                    image_type: 'play', // [play / image / icon]
                    image: 'free',
                    section_color: 'section-play-free',
                    icon: 'play_free',
                    icon_color: 'white',
                    mobile_grid: 'inline' // [inline / grid]
                },
                classroom: {
                    image_type: 'play', // [play / image / icon]
                    image: 'classroom',
                    section_color: 'section-play-classroom',
                    icon: 'play_classroom',
                    icon_color: 'white'
                },
                exam: {
                    image_type: 'play', // [play / image / icon]
                    image: 'exam',
                    section_color: 'section-play-exam',
                    icon: 'play_exam',
                    icon_color: 'white'
                },
            },
            order: ["HIGHLIGHTS", "KNOWMORE", "PERIODIC_QUESTION", "TRAIL", "SCORM", "EXAM", "CLASSROOM_COURSE", "FREE"],
        },
        feeds: {
            enabled: true,
            cards: {
                date: true
            }
        },
        learn: {
            enabled: true,
            answer_double_click: true,
            order: ["PERIODIC_QUESTION", "TRAIL", "SCORM", "EXAM", "CLASSROOM_COURSE", "FREE", "VIRTUAL_LIBRARY"],
            trail: {
                list: 'themes', // [themes / courses]
                categories: {
                    grid: {
                        style: 'default', // [default / card]
                        size: 'large', // [small / large]
                        mobile: 'default', // [default / full]
                    },
                    stars: true,
                    steps: false,
                    workload: false,
                    progress: {
                        enabled: false,
                        type: 'bar', // [bar / circular]
                        label: true,
                        position: 'center' // [left / center / right]
                    }
                },
                vector_images: true,
                chars: true,
                type: 'stars', // [stars / step]
                workload: false,
                best_view: false,
                scorm_open_alert: true,
                rating_comments: true,
                completed_message: false,
                stage: {
                    medal: true,
                    shadow: true,
                    stars: true,
                    stages: false,
                    stage_type: true
                },
                unified_stages: {
                    display: true,
                    expanded: 'auto' // [true, false, auto]
                },
                result_images: {
                    win: {
                        female1: require('./dist/img/banner/img_background_result_win_1_1.jpg'),
                        female2: require('./dist/img/banner/img_background_result_win_1_2.jpg'),
                        male1: require('./dist/img/banner/img_background_result_win_2_1.jpg'),
                        male2: require('./dist/img/banner/img_background_result_win_2_2.jpg')
                    },
                    lose: require('./dist/img/banner/img_background_result_lose.jpg')
                }
            },
            scorm: {
                list: 'themes', // [themes / courses]
                categories: {
                    grid: {
                        style: 'default', // [default / card]
                        size: 'small', // [small / large]
                        mobile: 'default', // [default / full]
                    },
                    progress: {
                        enabled: true,
                        type: 'circular', // [bar / circular]
                        label: true,
                        position: 'center' // [left / center / right]
                    }
                },
            },
            exam: {
                navigation: 'top', // [bottom / top]
                score: true
            },
            free: {
                categories: {
                    grid: {
                        style: 'default', // [default / card]
                        size: 'small', // [small / large]
                        mobile: 'default', // [default / full]
                    },
                },
            }
        },
        ranking: {
            flag: {
                enabled: true,
                type: "country", // [country / states]
            },
            filter: true,
            max_positions: 0 // [infinite = 0 / limit number (min = 3)]
        },
        library: {
            have_category: true
        },
        manager: {
            head_cards: {
                scorm: true,
                classroom: true,
                trail: true,
            },
            table_team_employees: true,
            table_team_activities: true,
            table_all_users: true
        },
        my_history: {
            enabled: true,
            head_cards: {
                last_login: true,
                ranking: true,
                courses_obligatory: true,
                courses_completed: true,
            },
            my_activities: {
                enabled: true,
                type_course: true,
                type: true,
                date_start: true,
                date_finish: true,
                workload: true,
                certificate_validity: true,
                do_until: true,
                obligatory: true
            }
        },
        faq: {
            expanded: true
        },
        settings: {
            edit_profile: {
                avatar: {
                    enabled: true
                },
                first_name: {
                    enabled: true,
                    required: true
                },
                last_name: {
                    enabled: true,
                    required: true
                }
            }
        }
    },

    FLEURY: {
        app: {
            name: 'UniFleury',
            name_project: 'FLEURY',
            description: 'Aplicativo UniFleury',
            favicon: '/favicon/fleury/favicon.ico',
            code: '_p17',
        },
        logos: {
            app_icon: require('./dist/img/projects/fleury/logos/LogoAppIcon'),
            main: {
                color: require('./dist/img/projects/fleury/logos/LogoMainColor'),
                clean: require('./dist/img/projects/fleury/logos/LogoMainClean'),
            },
            group: {
                color: require('./dist/img/projects/fleury/logos/LogoGroupColor'),
                clean: require('./dist/img/projects/fleury/logos/LogoGroupClean'),
            },
            badge: {
                color: require('./dist/img/projects/fleury/logos/LogoBadgeColor'),
                clean: require('./dist/img/projects/fleury/logos/LogoBadgeClean'),
            },
            qranio: {
                color: require('./dist/img/svgr/LogoQranioColor'),
                clean: require('./dist/img/svgr/LogoQranioClean'),
            },
        },
        firebase: {
            config: {
                apiKey: 'AIzaSyCaEwtC1yOfR2a4cm6vjBTP_lYCzNnLLoA',
                authDomain: 'uniqi-fleury.firebaseapp.com',
                databaseURL: 'https://uniqi-fleury.firebaseio.com',
                projectId: 'uniqi-fleury',
                storageBucket: 'uniqi-fleury.appspot.com',
                messagingSenderId: '652238750573',
                appId: '1:652238750573:web:361f3a5d0c64a485f05064',
                measurementId: 'G-SDYTEDQTZ9'
            },
            vapid: {
                vapidKey: 'BKagOny0KF_2pCJQ3mmoL0ewzQ8rZu67a90dcac6bceabf1f31e9'
            }
        },
        captcha: {
            type: 'invisible', // [checkbox / invisible]
            key: '6LfKcMcZAAAAAEwIzzQU5FGvbfQgEU6rvrCBtshW',
            invisible_key: '6LciwcMZAAAAAEr5CY4rXrf17PYKQZARA3Gt1l79',
        },
        login_method: {
            qrlogin: {
                enabled: false,
                type: 'email' // [email / username]
            },
            ad_azure: {
                enabled: true,
                scopes: ["openid profile offline_access User.Read calendars.read", "openid", "profile", "User.Read"],
                style: 'button', // [button / link]
            },
            saml2: {
                enabled: true,
                style: 'button', // [button / link]
            },
            email: {
                login: {
                    enabled: true,
                    style: {
                        small_screen: 'link', // [form / button / link]
                        large_screen: 'link', // [form / button / link]
                    },
                    show_access_work_confirmation: false
                },
                signup: {
                    enabled: false,
                    display_button: {
                        intro: {
                            enabled: true,
                            style: {
                                small_screen: 'link', // [button / link]
                                large_screen: 'link', // [button / link]
                            }
                        },
                        login: {
                            enabled: true,
                            style: 'link', // [button / link]
                        }
                    },
                    have_country: true,
                    country_default: 'BR',
                    have_enterprise: false
                },
                forgot_password: {
                    enabled: true
                }
            },
            username: {
                login: {
                    enabled: false,
                    style: {
                        small_screen: 'button', // [form / button / link]
                        large_screen: 'form', // [form / button / link]
                    },
                    label: 'LOGIN', // [LOGIN / CPF / DRT]
                    show_access_work_confirmation: false
                },
                signup: {
                    enabled: false,
                    display_button: {
                        intro: {
                            enabled: true,
                            style: {
                                small_screen: 'button', // [button / link]
                                large_screen: 'link', // [button / link]
                            }
                        },
                        login: {
                            enabled: true,
                            style: 'link', // [button / link]
                        }
                    },
                    have_country: true,
                    country_default: 'BR',
                    have_enterprise: false
                },
                forgot_password: {
                    enabled: true
                }
            },
            password: {
                complex: false,
                min: 8
            },
            first_access: {
                enabled: false,
                code_verify: false,
                questions: false,
                number_questions: 5
            },
            complete_data: {
                form: {
                    cell_number: {
                        enabled: false,
                        required: false
                    },
                    city: {
                        enabled: false,
                        required: false
                    },
                    country: {
                        enabled: false,
                        required: false
                    },
                    email: {
                        enabled: false,
                        required: false,
                        domain: ["general"] // Array[general / domains]
                    },
                    birthdate: {
                        enabled: false,
                        required: false
                    },
                    state: {
                        enabled: false,
                        required: false
                    },
                    brand: {
                        enabled: false,
                        required: false
                    },
                    role: {
                        enabled: false,
                        required: false
                    },
                    role_family: {
                        enabled: false,
                        required: false
                    },
                    role_group: {
                        enabled: false,
                        required: false
                    },
                    position: {
                        enabled: false,
                        required: false
                    },
                    regional: {
                        enabled: false,
                        required: false
                    },
                    subarea: {
                        enabled: false,
                        required: false
                    },
                    board: {
                        enabled: false,
                        required: false
                    },
                    executive_board: {
                        enabled: false,
                        required: false
                    },
                    managing: {
                        enabled: false,
                        required: false
                    },
                    cost_center: {
                        enabled: false,
                        required: false
                    },
                    enterprise: {
                        enabled: false,
                        required: false
                    },
                }
            },
            have_alternative_code: false
        },
        intro: {
            logo: {
                small_screen: {
                    type: 'main-color', // [app-icon, main-color, main-clean, group-color, group-clean, badge-color, badge-clean, qranio-color, qranio-clean]
                    size: 64
                },
                large_screen: {
                    type: 'main-color',
                    size: 80
                },
                footer: {
                    qranio: {
                        display: true,
                        type: 'qranio-color',
                        size: 24
                    },
                    group: {
                        display: false,
                        type: 'group-color',
                        size: 24
                    }
                }
            },
            type: 1, // [1 (com slider) / 2 (sem slider)]
            large_split_screen: true,
            vector_images: false,
            slider: [
                {
                    type: 'Course',
                    enabled: true,
                    text: 'Aqui você aprende de uma maneira divertida',
                },
                {
                    type: 'Duel',
                    enabled: false,
                    text: 'Tenha a liberdade de duelar com seus amigos',
                },
                {
                    type: 'Onboarding',
                    enabled: false,
                    text: 'Ganhe reconhecimento',
                },
                {
                    type: 'Ranking',
                    enabled: false,
                    text: 'Entre para o topo do ranking entre nossos usuários',
                },
                {
                    type: 'Prizes',
                    enabled: false,
                    text: 'Troque prêmios através de moedas adquiridas com seu conhecimento',
                },
                {
                    type: 'Feeds',
                    enabled: false,
                    text: 'Comente e interaja através de diversos conteúdos',
                },
                {
                    type: 'Scorm',
                    enabled: true,
                    text: 'Acompanhe todo o seu desenvolvimento',
                },
            ],
            show_popup: true,
            show_title: true,
            show_footer: true
        },
        layout: {
            theme: 'LIGHT',
            input_variant: 'outlined', // [outlined / filled / standard]
            char: {
                gender: 'random', // [female / male / random]
                color: 'random', // [black / white / random]
            },
            points: {
                neuros: false,
                qis: true
            },
            menu: {
                expanded: false,
                nav_order: ["MENU_HOME", "MENU_NOTIFICATIONS", "MENU_LEARN", "MENU_PRIZES", "MENU_LIBRARY", "MENU_RANKING", "MENU_MY_REDEEMS", "MENU_MY_HISTORY", "MENU_MANAGER_PANEL",
                    "MENU_FEEDS", "MENU_CODE_REDEEM", "MENU_FAQ", "MENU_SETTINGS"],
                nav_bottom_order: ["MENU_HOME", "MENU_FEEDS", "MENU_LEARN", "MENU_MY_HISTORY", "MENU_MORE"],
                logout_button: true,
                logo_footer: false
            },
            navbar: true,
            sidebar: {
                expanded: true
            },
            files: {
                download: false
            },
            videos: {
                download: false,
                picture_in_picture: true
            }
        },
        home: {
            empty_modules_visible: false,
            sidebar: true,
            sections: {
                highlight: {
                    enabled: true,
                    show_mobile: {
                        title: false,
                        text: false
                    }
                },
                knowmore: {
                    image_type: 'icon', // [play / image / icon]
                    image: '',
                    section_color: 'section-home',
                    icon: 'info_popup',
                    icon_color: 'white',
                    enabled: false
                },
                trail: {
                    image_type: 'play', // [play / image / icon]
                    image: 'trail',
                    section_color: 'section-play-trail',
                    icon: 'play_trail',
                    icon_color: 'white',
                    categories: 'courses', // [themes / courses]
                    mobile_grid: 'inline' // [inline / grid]
                },
                scorm: {
                    image_type: 'play', // [play / image / icon]
                    image: 'scorm',
                    section_color: 'section-play-scorm',
                    icon: 'play_scorm',
                    icon_color: 'white',
                    categories: 'courses', // [themes / courses]
                    mobile_grid: 'inline' // [inline / grid]
                },
                free: {
                    image_type: 'play', // [play / image / icon]
                    image: 'free',
                    section_color: 'section-play-free',
                    icon: 'play_free',
                    icon_color: 'white',
                    mobile_grid: 'inline' // [inline / grid]
                },
                classroom: {
                    image_type: 'play', // [play / image / icon]
                    image: 'classroom',
                    section_color: 'section-play-classroom',
                    icon: 'play_classroom',
                    icon_color: 'white'
                },
                exam: {
                    image_type: 'play', // [play / image / icon]
                    image: 'exam',
                    section_color: 'section-play-exam',
                    icon: 'play_exam',
                    icon_color: 'white'
                },
            },
            order: ["HIGHLIGHTS", "TRAIL", "EXAM", "CLASSROOM_COURSE", "KNOWMORE", "PERIODIC_QUESTION", "FREE"],
        },
        feeds: {
            enabled: true,
            cards: {
                date: true
            }
        },
        learn: {
            enabled: true,
            answer_double_click: true,
            order: ["TRAIL", "EXAM", "CLASSROOM_COURSE", "FREE", "VIRTUAL_LIBRARY", "PERIODIC_QUESTION"],
            trail: {
                list: 'themes', // [themes / courses]
                categories: {
                    grid: {
                        style: 'default', // [default / card]
                        size: 'large', // [small / large]
                        mobile: 'default', // [default / full]
                    },
                    stars: false,
                    steps: false,
                    workload: false,
                    progress: {
                        enabled: true,
                        type: 'circular', // [bar / circular]
                        label: true,
                        position: 'center' // [left / center / right]
                    }
                },
                vector_images: false,
                chars: false,
                type: 'step', // [stars / step]
                workload: true,
                best_view: true,
                scorm_open_alert: false,
                rating_comments: true,
                completed_message: false,
                stage: {
                    medal: false,
                    shadow: false,
                    stars: false,
                    stages: true,
                    stage_type: true
                },
                unified_stages: {
                    display: true,
                    expanded: true // [true, false, auto]
                },
                result_images: {
                    win: {
                        female1: require('./dist/img/banner/img_background_result_win_1_1.jpg'),
                        female2: require('./dist/img/banner/img_background_result_win_1_2.jpg'),
                        male1: require('./dist/img/banner/img_background_result_win_2_1.jpg'),
                        male2: require('./dist/img/banner/img_background_result_win_2_2.jpg')
                    },
                    lose: require('./dist/img/banner/img_background_result_lose.jpg')
                }
            },
            scorm: {
                list: 'themes', // [themes / courses]
                categories: {
                    grid: {
                        style: 'default', // [default / card]
                        size: 'small', // [small / large]
                        mobile: 'default', // [default / full]
                    },
                    progress: {
                        enabled: true,
                        type: 'circular', // [bar / circular]
                        label: true,
                        position: 'center' // [left / center / right]
                    }
                },
            },
            exam: {
                navigation: 'top', // [bottom / top]
                score: true
            },
            free: {
                categories: {
                    grid: {
                        style: 'default', // [default / card]
                        size: 'small', // [small / large]
                        mobile: 'default', // [default / full]
                    },
                },
            }
        },
        ranking: {
            flag: {
                enabled: true,
                type: "country", // [country / states]
            },
            filter: true,
            max_positions: 0 // [infinite = 0 / limit number (min = 3)]
        },
        library: {
            have_category: true
        },
        manager: {
            head_cards: {
                scorm: false,
                classroom: true,
                trail: true,
            },
            table_team_employees: true,
            table_team_activities: false,
            table_all_users: true
        },
        my_history: {
            enabled: true,
            head_cards: {
                last_login: true,
                ranking: true,
                courses_obligatory: true,
                courses_completed: true,
            },
            my_activities: {
                enabled: true,
                type_course: true,
                type: false,
                date_start: true,
                date_finish: true,
                workload: true,
                certificate_validity: false,
                do_until: false,
                obligatory: true
            }
        },
        faq: {
            expanded: false
        },
        settings: {
            edit_profile: {
                avatar: {
                    enabled: true
                },
                first_name: {
                    enabled: false,
                    required: false
                },
                last_name: {
                    enabled: false,
                    required: false
                }
            }
        }
    },

    ASSAI: {
        app: {
            name: 'Seja Bem-vindo ao Assaí',
            name_project: 'ASSAI',
            description: 'Aplicativo da Seja Bem-vindo ao Assaí',
            favicon: '/favicon/assai/favicon.ico',
            code: '_p21',
        },
        logos: {
            app_icon: require('./dist/img/projects/assai/logos/LogoAppIcon'),
            main: {
                color: require('./dist/img/projects/assai/logos/LogoMainColor'),
                clean: require('./dist/img/projects/assai/logos/LogoMainClean'),
            },
            group: {
                color: require('./dist/img/projects/assai/logos/LogoGroupColor'),
                clean: require('./dist/img/projects/assai/logos/LogoGroupClean'),
            },
            badge: {
                color: require('./dist/img/projects/assai/logos/LogoMainColor'),
                clean: require('./dist/img/projects/assai/logos/LogoMainClean'),
            },
            qranio: {
                color: require('./dist/img/svgr/LogoQranioColor'),
                clean: require('./dist/img/svgr/LogoQranioClean'),
            },
        },
        firebase: {
            config: {
                apiKey: "AIzaSyDlh3TH44FOKXc1n_f-UdoyWwmPWyd-r8o",
                authDomain: "assai-apps.firebaseapp.com",
                projectId: "assai-apps",
                storageBucket: "assai-apps.appspot.com",
                messagingSenderId: "1097148167599",
                appId: "1:1097148167599:web:4fc53017f231d470b8eb5a",
                measurementId: "G-SCL9P28X6E"
            },
            vapid: {
                vapidKey: 'BKagOny0KF_2pCJQ3mmoL0ewzQ8rZu67a90dcac6bceabf1f31e9'
            }
        },
        captcha: {
            type: 'invisible', // [checkbox / invisible]
            key: '6LfEcMcZAAAAABSNkkolcm119GF9RpuMXLvjrRb0',
            invisible_key: '6LdAwcMZAAAAAAvGzYGuEVF3nuhy8fFN7-qLZXZ8',
        },
        login_method: {
            qrlogin: {
                enabled: false,
                type: 'email' // [email / username]
            },
            ad_azure: {
                enabled: false,
                scopes: ["profile", "User.Read"],
                style: 'button', // [button / link]
            },
            saml2: {
                enabled: false,
                style: 'button', // [button / link]
            },
            email: {
                login: {
                    enabled: false,
                    style: {
                        small_screen: 'button', // [form / button / link]
                        large_screen: 'form', // [form / button / link]
                    },
                    show_access_work_confirmation: false
                },
                signup: {
                    enabled: false,
                    display_button: {
                        intro: {
                            enabled: true,
                            style: {
                                small_screen: 'button', // [button / link]
                                large_screen: 'link', // [button / link]
                            }
                        },
                        login: {
                            enabled: true,
                            style: 'link', // [button / link]
                        }
                    },
                    have_country: true,
                    country_default: 'BR',
                    have_enterprise: false
                },
                forgot_password: {
                    enabled: false
                }
            },
            username: {
                login: {
                    enabled: true,
                    style: {
                        small_screen: 'button', // [form / button / link]
                        large_screen: 'form', // [form / button / link]
                    },
                    label: 'CPF', // [LOGIN / CPF / DRT]
                    show_access_work_confirmation: false
                },
                signup: {
                    enabled: false,
                    display_button: {
                        intro: {
                            enabled: true,
                            style: {
                                small_screen: 'button', // [button / link]
                                large_screen: 'link', // [button / link]
                            }
                        },
                        login: {
                            enabled: true,
                            style: 'link', // [button / link]
                        }
                    },
                    have_country: true,
                    country_default: 'BR',
                    have_enterprise: false
                },
                forgot_password: {
                    enabled: true
                }
            },
            password: {
                complex: false,
                min: 8
            },
            first_access: {
                enabled: false,
                code_verify: false,
                questions: false,
                number_questions: 5
            },
            complete_data: {
                form: {
                    cell_number: {
                        enabled: false,
                        required: false
                    },
                    city: {
                        enabled: false,
                        required: false
                    },
                    country: {
                        enabled: false,
                        required: false
                    },
                    email: {
                        enabled: false,
                        required: false,
                        domain: ["general"] // Array[general / domains]
                    },
                    birthdate: {
                        enabled: false,
                        required: false
                    },
                    state: {
                        enabled: false,
                        required: false
                    },
                    brand: {
                        enabled: false,
                        required: false
                    },
                    role: {
                        enabled: false,
                        required: false
                    },
                    role_family: {
                        enabled: false,
                        required: false
                    },
                    role_group: {
                        enabled: false,
                        required: false
                    },
                    position: {
                        enabled: false,
                        required: false
                    },
                    regional: {
                        enabled: false,
                        required: false
                    },
                    subarea: {
                        enabled: false,
                        required: false
                    },
                    board: {
                        enabled: false,
                        required: false
                    },
                    executive_board: {
                        enabled: false,
                        required: false
                    },
                    managing: {
                        enabled: false,
                        required: false
                    },
                    cost_center: {
                        enabled: false,
                        required: false
                    },
                    enterprise: {
                        enabled: false,
                        required: false
                    },
                }
            },
            have_alternative_code: false
        },
        intro: {
            logo: {
                small_screen: {
                    type: 'main-color', // [app-icon, main-color, main-clean, group-color, group-clean, badge-color, badge-clean, qranio-color, qranio-clean]
                    size: 64
                },
                large_screen: {
                    type: 'main-color',
                    size: 80
                },
                footer: {
                    qranio: {
                        display: true,
                        type: 'qranio-color',
                        size: 24
                    },
                    group: {
                        display: true,
                        type: 'group-color',
                        size: 24
                    }
                }
            },
            type: 1, // [1 (com slider) / 2 (sem slider)]
            large_split_screen: false,
            vector_images: true,
            slider: [
                {
                    type: 'Slide1',
                    enabled: true,
                    text: 'Olá! Seja bem-vindo(a) à Plataforma de Integração do Nosso Assaí.',
                },
                {
                    type: 'Slide2',
                    enabled: true,
                    text: 'Aqui você irá aprender tudo sobre o Nosso Assaí de forma leve e interativa.',
                },
                {
                    type: 'Slide3',
                    enabled: true,
                    text: 'Esse é o primeiro passo para seu desenvolvimento. Aproveite!',
                },
                {
                    type: 'Slide4',
                    enabled: false,
                    text: '',
                }
            ],
            show_popup: true,
            show_title: true,
            show_footer: true
        },
        layout: {
            theme: 'LIGHT',
            input_variant: 'outlined', // [outlined / filled / standard]
            char: {
                gender: 'random', // [female / male / random]
                color: 'random', // [black / white / random]
            },
            points: {
                neuros: false,
                qis: false
            },
            menu: {
                expanded: false,
                nav_order: ["MENU_HOME", "MENU_FEEDS", "MENU_LEARN", "MENU_PRIZES", "MENU_RANKING", "MENU_NOTIFICATIONS", "MENU_LIBRARY",
                    "MENU_MY_REDEEMS", "MENU_CODE_REDEEM", "MENU_MY_HISTORY", "MENU_MANAGER_PANEL", "MENU_FAQ", "MENU_SETTINGS"],
                nav_bottom_order: ["MENU_HOME", "MENU_NOTIFICATIONS", "MENU_LEARN", "MENU_MY_HISTORY", "MENU_MORE"],
                logout_button: true,
                logo_footer: true
            },
            navbar: true,
            sidebar: {
                expanded: true
            },
            files: {
                download: false
            },
            videos: {
                download: false,
                picture_in_picture: true
            }
        },
        home: {
            empty_modules_visible: true,
            sidebar: true,
            sections: {
                highlight: {
                    enabled: true,
                    show_mobile: {
                        title: true,
                        text: true
                    }
                },
                knowmore: {
                    image_type: 'icon', // [play / image / icon]
                    image: '',
                    section_color: 'section-home',
                    icon: 'info_popup',
                    icon_color: 'white',
                    enabled: true
                },
                trail: {
                    image_type: 'play', // [play / image / icon]
                    image: 'trail',
                    section_color: 'section-play-trail',
                    icon: 'play_trail',
                    icon_color: 'white',
                    categories: 'courses', // [themes / courses]
                    mobile_grid: 'inline' // [inline / grid]
                },
                scorm: {
                    image_type: 'play', // [play / image / icon]
                    image: 'scorm',
                    section_color: 'section-play-scorm',
                    icon: 'play_scorm',
                    icon_color: 'white',
                    categories: 'courses', // [themes / courses]
                    mobile_grid: 'inline' // [inline / grid]
                },
                free: {
                    image_type: 'play', // [play / image / icon]
                    image: 'free',
                    section_color: 'section-play-free',
                    icon: 'play_free',
                    icon_color: 'white',
                    mobile_grid: 'inline' // [inline / grid]
                },
                classroom: {
                    image_type: 'play', // [play / image / icon]
                    image: 'classroom',
                    section_color: 'section-play-classroom',
                    icon: 'play_classroom',
                    icon_color: 'white'
                },
                exam: {
                    image_type: 'play', // [play / image / icon]
                    image: 'exam',
                    section_color: 'section-play-exam',
                    icon: 'play_exam',
                    icon_color: 'white'
                },
            },
            order: ["HIGHLIGHTS", "SCORM", "TRAIL", "KNOWMORE", "PERIODIC_QUESTION", "FREE", "EXAM", "CLASSROOM_COURSE"],
        },
        feeds: {
            enabled: false,
            cards: {
                date: true
            }
        },
        learn: {
            enabled: true,
            answer_double_click: false,
            order: ["PERIODIC_QUESTION", "FREE", "TRAIL", "EXAM", "CLASSROOM_COURSE", "SCORM", "VIRTUAL_LIBRARY"],
            trail: {
                list: 'themes', // [themes / courses]
                categories: {
                    grid: {
                        style: 'default', // [default / card]
                        size: 'large', // [small / large]
                        mobile: 'default', // [default / full]
                    },
                    stars: true,
                    steps: false,
                    workload: false,
                    progress: {
                        enabled: false,
                        type: 'bar', // [bar / circular]
                        label: true,
                        position: 'center' // [left / center / right]
                    }
                },
                vector_images: true,
                chars: true,
                type: 'stars', // [stars / step]
                workload: false,
                best_view: false,
                scorm_open_alert: true,
                rating_comments: true,
                completed_message: false,
                stage: {
                    medal: true,
                    shadow: true,
                    stars: true,
                    stages: false,
                    stage_type: true
                },
                unified_stages: {
                    display: true,
                    expanded: 'auto' // [true, false, auto]
                },
                result_images: {
                    win: {
                        female1: require('./dist/img/banner/img_background_result_win_1_1.jpg'),
                        female2: require('./dist/img/banner/img_background_result_win_1_2.jpg'),
                        male1: require('./dist/img/banner/img_background_result_win_2_1.jpg'),
                        male2: require('./dist/img/banner/img_background_result_win_2_2.jpg')
                    },
                    lose: require('./dist/img/banner/img_background_result_lose.jpg')
                }
            },
            scorm: {
                list: 'themes', // [themes / courses]
                categories: {
                    grid: {
                        style: 'default', // [default / card]
                        size: 'small', // [small / large]
                        mobile: 'default', // [default / full]
                    },
                    progress: {
                        enabled: true,
                        type: 'circular', // [bar / circular]
                        label: true,
                        position: 'center' // [left / center / right]
                    }
                },
            },
            exam: {
                navigation: 'top', // [bottom / top]
                score: true
            },
            free: {
                categories: {
                    grid: {
                        style: 'default', // [default / card]
                        size: 'small', // [small / large]
                        mobile: 'default', // [default / full]
                    },
                },
            }
        },
        ranking: {
            flag: {
                enabled: true,
                type: "country", // [country / states]
            },
            filter: true,
            max_positions: 0 // [infinite = 0 / limit number (min = 3)]
        },
        library: {
            have_category: true
        },
        manager: {
            head_cards: {
                scorm: true,
                classroom: true,
                trail: true,
            },
            table_team_employees: true,
            table_team_activities: true,
            table_all_users: false
        },
        my_history: {
            enabled: true,
            head_cards: {
                last_login: true,
                ranking: true,
                courses_obligatory: true,
                courses_completed: true,
            },
            my_activities: {
                enabled: true,
                type_course: true,
                type: false,
                date_start: true,
                date_finish: true,
                workload: true,
                certificate_validity: false,
                do_until: false,
                obligatory: true
            }
        },
        faq: {
            expanded: true
        },
        settings: {
            edit_profile: {
                avatar: {
                    enabled: true
                },
                first_name: {
                    enabled: false,
                    required: false
                },
                last_name: {
                    enabled: false,
                    required: false
                }
            }
        }
    },

    NED: {
        app: {
            name: 'NED',
            name_project: 'NED',
            description: 'Aplicativo NED',
            favicon: '/favicon/ned/favicon.ico',
            code: '_p22',
        },
        logos: {
            app_icon: require('./dist/img/projects/ned/logos/LogoAppIcon'),
            main: {
                color: require('./dist/img/projects/ned/logos/LogoMainColor'),
                clean: require('./dist/img/projects/ned/logos/LogoMainClean'),
            },
            group: {
                color: require('./dist/img/svgr/LogoQranioColor'),
                clean: require('./dist/img/svgr/LogoQranioClean'),
            },
            badge: {
                color: require('./dist/img/projects/qranio/logos/LogoBadgeColor'),
                clean: require('./dist/img/projects/qranio/logos/LogoBadgeClean'),
            },
            qranio: {
                color: require('./dist/img/svgr/LogoQranioColor'),
                clean: require('./dist/img/svgr/LogoQranioClean'),
            },
        },
        firebase: {
            config: {
                apiKey: 'AIzaSyB2wNdlvmb0u8rL8KQWk8iPs3WlUkywHTw',
                authDomain: 'app-qr-ned.firebaseapp.com',
                databaseURL: 'https://app-qr-ned.firebaseio.com',
                projectId: 'app-qr-ned',
                storageBucket: 'app-qr-ned.appspot.com',
                messagingSenderId: '247740903247',
                appId: '1:247740903247:web:cdd380cf5923e9ff1edf3a',
                measurementId: 'G-M6DTQ5FSSH'
            },
            vapid: {
                vapidKey: 'BKagOny0KF_2pCJQ3mmoL0ewzQ8rZu67a90dcac6bceabf1f31e9'
            }
        },
        captcha: {
            type: 'invisible', // [checkbox / invisible]
            key: '6Lcx3z4cAAAAAIa_gGR-n23YQ1sJPd9QPth_D1Vb',
            invisible_key: '6Lcsqj4cAAAAAJ1ZlCpmVVoR9nCyuszYuX7Bd_6a',
        },
        login_method: {
            qrlogin: {
                enabled: false,
                type: 'email' // [email / username]
            },
            ad_azure: {
                enabled: false,
                scopes: ["profile", "User.Read"],
                style: 'button', // [button / link]
            },
            saml2: {
                enabled: false,
                style: 'button', // [button / link]
            },
            email: {
                login: {
                    enabled: false,
                    style: {
                        small_screen: 'button', // [form / button / link]
                        large_screen: 'form', // [form / button / link]
                    },
                    show_access_work_confirmation: false
                },
                signup: {
                    enabled: false,
                    display_button: {
                        intro: {
                            enabled: true,
                            style: {
                                small_screen: 'button', // [button / link]
                                large_screen: 'link', // [button / link]
                            }
                        },
                        login: {
                            enabled: true,
                            style: 'link', // [button / link]
                        }
                    },
                    have_country: true,
                    country_default: 'BR',
                    have_enterprise: false
                },
                forgot_password: {
                    enabled: false
                }
            },
            username: {
                login: {
                    enabled: true,
                    style: {
                        small_screen: 'button', // [form / button / link]
                        large_screen: 'form', // [form / button / link]
                    },
                    label: 'CPF', // [LOGIN / CPF / DRT]
                    show_access_work_confirmation: false
                },
                signup: {
                    enabled: true,
                    display_button: {
                        intro: {
                            enabled: true,
                            style: {
                                small_screen: 'button', // [button / link]
                                large_screen: 'link', // [button / link]
                            }
                        },
                        login: {
                            enabled: true,
                            style: 'link', // [button / link]
                        }
                    },
                    have_country: false,
                    country_default: 'BR',
                    have_enterprise: true
                },
                forgot_password: {
                    enabled: false
                }
            },
            password: {
                complex: false,
                min: 8
            },
            first_access: {
                enabled: true,
                code_verify: false,
                questions: false,
                number_questions: 5
            },
            complete_data: {
                form: {
                    cell_number: {
                        enabled: false,
                        required: false
                    },
                    city: {
                        enabled: false,
                        required: false
                    },
                    country: {
                        enabled: true,
                        required: false
                    },
                    email: {
                        enabled: false,
                        required: false,
                        domain: ["general"] // Array[general / domains]
                    },
                    birthdate: {
                        enabled: true,
                        required: false
                    },
                    state: {
                        enabled: false,
                        required: false
                    },
                    brand: {
                        enabled: false,
                        required: false
                    },
                    role: {
                        enabled: false,
                        required: false
                    },
                    role_family: {
                        enabled: false,
                        required: false
                    },
                    role_group: {
                        enabled: false,
                        required: false
                    },
                    position: {
                        enabled: false,
                        required: false
                    },
                    regional: {
                        enabled: false,
                        required: false
                    },
                    subarea: {
                        enabled: false,
                        required: false
                    },
                    board: {
                        enabled: false,
                        required: false
                    },
                    executive_board: {
                        enabled: false,
                        required: false
                    },
                    managing: {
                        enabled: false,
                        required: false
                    },
                    cost_center: {
                        enabled: false,
                        required: false
                    },
                    enterprise: {
                        enabled: false,
                        required: false
                    },
                }
            },
            have_alternative_code: false
        },
        intro: {
            logo: {
                small_screen: {
                    type: 'main-color', // [app-icon, main-color, main-clean, group-color, group-clean, badge-color, badge-clean, qranio-color, qranio-clean]
                    size: 64
                },
                large_screen: {
                    type: 'main-color',
                    size: 80
                },
                footer: {
                    qranio: {
                        display: true,
                        type: 'qranio-color',
                        size: 24
                    },
                    group: {
                        display: false,
                        type: 'group-color',
                        size: 24
                    }
                }
            },
            type: 2, // [1 (com slider) / 2 (sem slider)]
            large_split_screen: false,
            vector_images: true,
            slider: [
                {
                    type: 'Course',
                    enabled: true,
                    text: 'Aqui você aprende de uma maneira divertida',
                },
                {
                    type: 'Duel',
                    enabled: false,
                    text: 'Tenha a liberdade de duelar com seus amigos',
                },
                {
                    type: 'Onboarding',
                    enabled: true,
                    text: 'Ganhe reconhecimento',
                },
                {
                    type: 'Ranking',
                    enabled: false,
                    text: 'Entre para o topo do ranking entre nossos usuários',
                },
                {
                    type: 'Prizes',
                    enabled: false,
                    text: 'Troque prêmios através de moedas adquiridas com seu conhecimento',
                },
                {
                    type: 'Feeds',
                    enabled: false,
                    text: 'Comente e interaja através de diversos conteúdos',
                },
                {
                    type: 'Scorm',
                    enabled: true,
                    text: 'Acompanhe todo o seu desenvolvimento',
                },
            ],
            show_popup: true,
            show_title: true,
            show_footer: true
        },
        layout: {
            theme: 'DARK',
            input_variant: 'outlined', // [outlined / filled / standard]
            char: {
                gender: 'random', // [female / male / random]
                color: 'random', // [black / white / random]
            },
            points: {
                neuros: false,
                qis: false
            },
            menu: {
                expanded: false,
                nav_order: ["MENU_HOME", "MENU_FEEDS", "MENU_LEARN", "MENU_PRIZES", "MENU_RANKING", "MENU_NOTIFICATIONS", "MENU_LIBRARY",
                    "MENU_MY_REDEEMS", "MENU_CODE_REDEEM", "MENU_MY_HISTORY", "MENU_MANAGER_PANEL", "MENU_FAQ", "MENU_SETTINGS"],
                nav_bottom_order: ["MENU_HOME", "MENU_FEEDS", "MENU_LEARN", "MENU_MY_HISTORY", "MENU_MORE"],
                logout_button: true,
                logo_footer: false
            },
            navbar: true,
            sidebar: {
                expanded: true
            },
            files: {
                download: false
            },
            videos: {
                download: false,
                picture_in_picture: true
            }
        },
        home: {
            empty_modules_visible: true,
            sidebar: true,
            sections: {
                highlight: {
                    enabled: false,
                    show_mobile: {
                        title: true,
                        text: true
                    }
                },
                knowmore: {
                    image_type: 'icon', // [play / image / icon]
                    image: '',
                    section_color: 'section-home',
                    icon: 'info_popup',
                    icon_color: 'white',
                    enabled: false
                },
                trail: {
                    image_type: 'play', // [play / image / icon]
                    image: 'trail',
                    section_color: 'section-play-trail',
                    icon: 'play_trail',
                    icon_color: 'white',
                    categories: 'courses', // [themes / courses]
                    mobile_grid: 'inline' // [inline / grid]
                },
                scorm: {
                    image_type: 'play', // [play / image / icon]
                    image: 'scorm',
                    section_color: 'section-play-scorm',
                    icon: 'play_scorm',
                    icon_color: 'white',
                    categories: 'courses', // [themes / courses]
                    mobile_grid: 'inline' // [inline / grid]
                },
                free: {
                    image_type: 'play', // [play / image / icon]
                    image: 'free',
                    section_color: 'section-play-free',
                    icon: 'play_free',
                    icon_color: 'white',
                    mobile_grid: 'inline' // [inline / grid]
                },
                classroom: {
                    image_type: 'play', // [play / image / icon]
                    image: 'classroom',
                    section_color: 'section-play-classroom',
                    icon: 'play_classroom',
                    icon_color: 'white'
                },
                exam: {
                    image_type: 'play', // [play / image / icon]
                    image: 'exam',
                    section_color: 'section-play-exam',
                    icon: 'play_exam',
                    icon_color: 'white'
                },
            },
            order: ["HIGHLIGHTS", "KNOWMORE", "PERIODIC_QUESTION", "FREE", "TRAIL", "EXAM", "CLASSROOM_COURSE", "SCORM"],
        },
        feeds: {
            enabled: true,
            cards: {
                date: true
            }
        },
        learn: {
            enabled: true,
            answer_double_click: true,
            order: ["PERIODIC_QUESTION", "FREE", "TRAIL", "EXAM", "CLASSROOM_COURSE", "SCORM", "VIRTUAL_LIBRARY"],
            trail: {
                list: 'themes', // [themes / courses]
                categories: {
                    grid: {
                        style: 'default', // [default / card]
                        size: 'large', // [small / large]
                        mobile: 'default', // [default / full]
                    },
                    stars: true,
                    steps: false,
                    workload: false,
                    progress: {
                        enabled: false,
                        type: 'bar', // [bar / circular]
                        label: true,
                        position: 'center' // [left / center / right]
                    }
                },
                vector_images: true,
                chars: true,
                type: 'stars', // [stars / step]
                workload: false,
                best_view: false,
                scorm_open_alert: true,
                rating_comments: true,
                completed_message: false,
                stage: {
                    medal: true,
                    shadow: true,
                    stars: true,
                    stages: false,
                    stage_type: true
                },
                unified_stages: {
                    display: true,
                    expanded: 'auto' // [true, false, auto]
                },
                result_images: {
                    win: {
                        female1: require('./dist/img/banner/img_background_result_win_1_1.jpg'),
                        female2: require('./dist/img/banner/img_background_result_win_1_2.jpg'),
                        male1: require('./dist/img/banner/img_background_result_win_2_1.jpg'),
                        male2: require('./dist/img/banner/img_background_result_win_2_2.jpg')
                    },
                    lose: require('./dist/img/banner/img_background_result_lose.jpg')
                }
            },
            scorm: {
                list: 'themes', // [themes / courses]
                categories: {
                    grid: {
                        style: 'default', // [default / card]
                        size: 'small', // [small / large]
                        mobile: 'default', // [default / full]
                    },
                    progress: {
                        enabled: true,
                        type: 'circular', // [bar / circular]
                        label: true,
                        position: 'center' // [left / center / right]
                    }
                },
            },
            exam: {
                navigation: 'top', // [bottom / top]
                score: true
            },
            free: {
                categories: {
                    grid: {
                        style: 'default', // [default / card]
                        size: 'small', // [small / large]
                        mobile: 'default', // [default / full]
                    },
                },
            }
        },
        ranking: {
            flag: {
                enabled: true,
                type: "country", // [country / states]
            },
            filter: true,
            max_positions: 0 // [infinite = 0 / limit number (min = 3)]
        },
        library: {
            have_category: true
        },
        manager: {
            head_cards: {
                scorm: true,
                classroom: true,
                trail: true,
            },
            table_team_employees: true,
            table_team_activities: true,
            table_all_users: false
        },
        my_history: {
            enabled: true,
            head_cards: {
                last_login: true,
                ranking: true,
                courses_obligatory: true,
                courses_completed: true,
            },
            my_activities: {
                enabled: true,
                type_course: true,
                type: false,
                date_start: true,
                date_finish: true,
                workload: true,
                certificate_validity: false,
                do_until: false,
                obligatory: true
            }
        },
        faq: {
            expanded: true
        },
        settings: {
            edit_profile: {
                avatar: {
                    enabled: true
                },
                first_name: {
                    enabled: true,
                    required: true
                },
                last_name: {
                    enabled: true,
                    required: true
                }
            }
        }
    },

    NOVARTIS: {
        app: {
            name: 'Novartis',
            name_project: 'NOVARTIS',
            description: 'Aplicativo Novartis',
            favicon: '/favicon/novartis/favicon.ico',
            code: '_p25',
        },
        logos: {
            app_icon: require('./dist/img/projects/novartis/logos/LogoAppIcon'),
            main: {
                color: require('./dist/img/projects/novartis/logos/LogoMainColor'),
                clean: require('./dist/img/projects/novartis/logos/LogoMainClean'),
            },
            group: {
                color: require('./dist/img/svgr/LogoQranioColor'),
                clean: require('./dist/img/svgr/LogoQranioClean'),
            },
            badge: {
                color: require('./dist/img/projects/qranio/logos/LogoBadgeColor'),
                clean: require('./dist/img/projects/qranio/logos/LogoBadgeClean'),
            },
            qranio: {
                color: require('./dist/img/svgr/LogoQranioColor'),
                clean: require('./dist/img/svgr/LogoQranioClean'),
            },
        },
        firebase: {
            config: {
                apiKey: "AIzaSyCIxg06LZrfcRJ_TJESG7g33I47Lui98VY",
                authDomain: "novartis-22b56.firebaseapp.com",
                databaseURL: 'https://novartis-22b56-default-rtdb.firebaseio.com',
                projectId: "novartis-22b56",
                storageBucket: "novartis-22b56.appspot.com",
                messagingSenderId: "397096235846",
                appId: "1:397096235846:web:49ee9a8564e6f5cb4deb47",
                measurementId: "G-4KVQ0YQYWB"
            },
            vapid: {
                vapidKey: 'BKagOny0KF_2pCJQ3mmoL0ewzQ8rZu67a90dcac6bceabf1f31e9'
            }
        },
        captcha: {
            type: 'invisible', // [checkbox / invisible]
            key: '6Lcm9vAmAAAAAJgT7bgL0ABFpHjHKsZwcDhIoxMJ',
            invisible_key: '6LeG9PAmAAAAAD4rR-0xItKaTK1hrmoFV_zAmO_4',
        },
        login_method: {
            qrlogin: {
                enabled: true,
                type: 'email' // [email / username]
            },
            ad_azure: {
                enabled: false,
                scopes: ["profile", "User.Read"],
                style: 'button', // [button / link]
            },
            saml2: {
                enabled: true,
                style: 'button', // [button / link]
            },
            email: {
                login: {
                    enabled: false,
                    style: {
                        small_screen: 'button', // [form / button / link]
                        large_screen: 'form', // [form / button / link]
                    },
                    show_access_work_confirmation: false
                },
                signup: {
                    enabled: false,
                    display_button: {
                        intro: {
                            enabled: true,
                            style: {
                                small_screen: 'button', // [button / link]
                                large_screen: 'link', // [button / link]
                            }
                        },
                        login: {
                            enabled: true,
                            style: 'link', // [button / link]
                        }
                    },
                    have_country: true,
                    country_default: 'BR',
                    have_enterprise: false
                },
                forgot_password: {
                    enabled: true
                }
            },
            username: {
                login: {
                    enabled: false,
                    style: {
                        small_screen: 'button', // [form / button / link]
                        large_screen: 'form', // [form / button / link]
                    },
                    label: 'LOGIN', // [LOGIN / CPF / DRT]
                    show_access_work_confirmation: false
                },
                signup: {
                    enabled: false,
                    display_button: {
                        intro: {
                            enabled: true,
                            style: {
                                small_screen: 'button', // [button / link]
                                large_screen: 'link', // [button / link]
                            }
                        },
                        login: {
                            enabled: true,
                            style: 'link', // [button / link]
                        }
                    },
                    have_country: true,
                    country_default: 'BR',
                    have_enterprise: false
                },
                forgot_password: {
                    enabled: false
                }
            },
            password: {
                complex: false,
                min: 8
            },
            first_access: {
                enabled: false,
                code_verify: false,
                questions: false,
                number_questions: 5
            },
            complete_data: {
                form: {
                    cell_number: {
                        enabled: false,
                        required: false
                    },
                    city: {
                        enabled: false,
                        required: false
                    },
                    country: {
                        enabled: false,
                        required: false
                    },
                    email: {
                        enabled: false,
                        required: false,
                        domain: ["general"] // Array[general / domains]
                    },
                    birthdate: {
                        enabled: false,
                        required: false
                    },
                    state: {
                        enabled: false,
                        required: false
                    },
                    brand: {
                        enabled: true,
                        required: true
                    },
                    role: {
                        enabled: true,
                        required: true
                    },
                    role_family: {
                        enabled: false,
                        required: false
                    },
                    role_group: {
                        enabled: false,
                        required: false
                    },
                    position: {
                        enabled: false,
                        required: false
                    },
                    regional: {
                        enabled: false,
                        required: false
                    },
                    subarea: {
                        enabled: false,
                        required: false
                    },
                    board: {
                        enabled: false,
                        required: false
                    },
                    executive_board: {
                        enabled: true,
                        required: true
                    },
                    managing: {
                        enabled: false,
                        required: false
                    },
                    cost_center: {
                        enabled: false,
                        required: false
                    },
                    enterprise: {
                        enabled: false,
                        required: false
                    },
                }
            },
            have_alternative_code: false
        },
        intro: {
            logo: {
                small_screen: {
                    type: 'main-color', // [app-icon, main-color, main-clean, group-color, group-clean, badge-color, badge-clean, qranio-color, qranio-clean]
                    size: 64
                },
                large_screen: {
                    type: 'main-color',
                    size: 80
                },
                footer: {
                    qranio: {
                        display: false,
                        type: 'qranio-color',
                        size: 24
                    },
                    group: {
                        display: false,
                        type: 'group-color',
                        size: 24
                    }
                }
            },
            type: 2, // [1 (com slider) / 2 (sem slider)]
            large_split_screen: false,
            vector_images: true,
            slider: [
                {
                    type: 'Course',
                    enabled: true,
                    text: 'Aqui você aprende de uma maneira divertida',
                },
                {
                    type: 'Duel',
                    enabled: false,
                    text: 'Tenha a liberdade de duelar com seus amigos',
                },
                {
                    type: 'Onboarding',
                    enabled: false,
                    text: 'Ganhe reconhecimento',
                },
                {
                    type: 'Ranking',
                    enabled: true,
                    text: 'Entre para o topo do ranking entre nossos usuários',
                },
                {
                    type: 'Prizes',
                    enabled: false,
                    text: 'Troque prêmios através de moedas adquiridas com seu conhecimento',
                },
                {
                    type: 'Feeds',
                    enabled: true,
                    text: 'Comente e interaja através de diversos conteúdos',
                },
                {
                    type: 'Scorm',
                    enabled: true,
                    text: 'Acompanhe todo o seu desenvolvimento',
                },
            ],
            show_popup: true,
            show_title: true,
            show_footer: false
        },
        layout: {
            theme: 'LIGHT',
            input_variant: 'outlined', // [outlined / filled / standard]
            char: {
                gender: 'random', // [female / male / random]
                color: 'random', // [black / white / random]
            },
            points: {
                neuros: false,
                qis: false
            },
            menu: {
                expanded: false,
                nav_order: ["MENU_HOME", "MENU_FEEDS", "MENU_LEARN", "MENU_PRIZES", "MENU_RANKING", "MENU_NOTIFICATIONS", "MENU_LIBRARY",
                    "MENU_MY_REDEEMS", "MENU_CODE_REDEEM", "MENU_MY_HISTORY", "MENU_MANAGER_PANEL", "MENU_FAQ", "MENU_SETTINGS"],
                nav_bottom_order: ["MENU_HOME", "MENU_FEEDS", "MENU_LEARN", "MENU_MY_HISTORY", "MENU_MORE"],
                logout_button: true,
                logo_footer: false
            },
            navbar: true,
            sidebar: {
                expanded: true
            },
            files: {
                download: false
            },
            videos: {
                download: false,
                picture_in_picture: true
            }
        },
        home: {
            empty_modules_visible: true,
            sidebar: true,
            sections: {
                highlight: {
                    enabled: false,
                    show_mobile: {
                        title: true,
                        text: true
                    }
                },
                knowmore: {
                    image_type: 'icon', // [play / image / icon]
                    image: '',
                    section_color: 'section-home',
                    icon: 'info_popup',
                    icon_color: 'white',
                    enabled: false
                },
                trail: {
                    image_type: 'play', // [play / image / icon]
                    image: 'trail',
                    section_color: 'section-play-trail',
                    icon: 'play_trail',
                    icon_color: 'white',
                    categories: 'courses', // [themes / courses]
                    mobile_grid: 'inline' // [inline / grid]
                },
                scorm: {
                    image_type: 'play', // [play / image / icon]
                    image: 'scorm',
                    section_color: 'section-play-scorm',
                    icon: 'play_scorm',
                    icon_color: 'white',
                    categories: 'courses', // [themes / courses]
                    mobile_grid: 'inline' // [inline / grid]
                },
                free: {
                    image_type: 'play', // [play / image / icon]
                    image: 'free',
                    section_color: 'section-play-free',
                    icon: 'play_free',
                    icon_color: 'white',
                    mobile_grid: 'inline' // [inline / grid]
                },
                classroom: {
                    image_type: 'play', // [play / image / icon]
                    image: 'classroom',
                    section_color: 'section-play-classroom',
                    icon: 'play_classroom',
                    icon_color: 'white'
                },
                exam: {
                    image_type: 'play', // [play / image / icon]
                    image: 'exam',
                    section_color: 'section-play-exam',
                    icon: 'play_exam',
                    icon_color: 'white'
                },
            },
            order: ["HIGHLIGHTS", "KNOWMORE", "TRAIL", "EXAM", "CLASSROOM_COURSE", "SCORM", "PERIODIC_QUESTION", "FREE"],
        },
        feeds: {
            enabled: true,
            cards: {
                date: true
            }
        },
        learn: {
            enabled: true,
            answer_double_click: true,
            order: ["TRAIL", "EXAM", "VIRTUAL_LIBRARY", "CLASSROOM_COURSE", "SCORM", "PERIODIC_QUESTION", "FREE"],
            trail: {
                list: 'themes', // [themes / courses]
                categories: {
                    grid: {
                        style: 'default', // [default / card]
                        size: 'large', // [small / large]
                        mobile: 'default', // [default / full]
                    },
                    stars: true,
                    steps: false,
                    workload: false,
                    progress: {
                        enabled: false,
                        type: 'bar', // [bar / circular]
                        label: true,
                        position: 'center' // [left / center / right]
                    }
                },
                vector_images: true,
                chars: true,
                type: 'stars', // [stars / step]
                workload: false,
                best_view: false,
                scorm_open_alert: true,
                rating_comments: true,
                completed_message: false,
                stage: {
                    medal: true,
                    shadow: true,
                    stars: true,
                    stages: false,
                    stage_type: true
                },
                unified_stages: {
                    display: true,
                    expanded: 'auto' // [true, false, auto]
                },
                result_images: {
                    win: {
                        female1: require('./dist/img/banner/img_background_result_win_1_1.jpg'),
                        female2: require('./dist/img/banner/img_background_result_win_1_2.jpg'),
                        male1: require('./dist/img/banner/img_background_result_win_2_1.jpg'),
                        male2: require('./dist/img/banner/img_background_result_win_2_2.jpg')
                    },
                    lose: require('./dist/img/banner/img_background_result_lose.jpg')
                }
            },
            scorm: {
                list: 'themes', // [themes / courses]
                categories: {
                    grid: {
                        style: 'default', // [default / card]
                        size: 'small', // [small / large]
                        mobile: 'default', // [default / full]
                    },
                    progress: {
                        enabled: true,
                        type: 'circular', // [bar / circular]
                        label: true,
                        position: 'center' // [left / center / right]
                    }
                },
            },
            exam: {
                navigation: 'top', // [bottom / top]
                score: true
            },
            free: {
                categories: {
                    grid: {
                        style: 'default', // [default / card]
                        size: 'small', // [small / large]
                        mobile: 'default', // [default / full]
                    },
                },
            }
        },
        ranking: {
            flag: {
                enabled: true,
                type: "country", // [country / states]
            },
            filter: true,
            max_positions: 0 // [infinite = 0 / limit number (min = 3)]
        },
        library: {
            have_category: true
        },
        manager: {
            head_cards: {
                scorm: true,
                classroom: true,
                trail: true,
            },
            table_team_employees: true,
            table_team_activities: true,
            table_all_users: false
        },
        my_history: {
            enabled: true,
            head_cards: {
                last_login: true,
                ranking: false,
                courses_obligatory: true,
                courses_completed: true,
            },
            my_activities: {
                enabled: true,
                type_course: true,
                type: false,
                date_start: true,
                date_finish: true,
                workload: true,
                certificate_validity: false,
                do_until: false,
                obligatory: true
            }
        },
        faq: {
            expanded: true
        },
        settings: {
            edit_profile: {
                avatar: {
                    enabled: true
                },
                first_name: {
                    enabled: false,
                    required: false
                },
                last_name: {
                    enabled: false,
                    required: false
                }
            }
        }
    },

    ASTRAZENECA: {
        app: {
            name: 'Mestres do Conhecimento',
            name_project: 'ASTRAZENECA',
            description: 'Aplicativo',
            favicon: '/favicon/astrazeneca/favicon.ico',
            code: '_p27',
        },
        logos: {
            app_icon: require('./dist/img/projects/astrazeneca/logos/LogoAppIcon'),
            main: {
                color: require('./dist/img/projects/astrazeneca/logos/LogoMainColor'),
                clean: require('./dist/img/projects/astrazeneca/logos/LogoMainClean'),
            },
            group: {
                color: require('./dist/img/projects/astrazeneca/logos/LogoGroupColor'),
                clean: require('./dist/img/projects/astrazeneca/logos/LogoGroupClean'),
            },
            badge: {
                color: require('./dist/img/projects/astrazeneca/logos/LogoBadgeColor'),
                clean: require('./dist/img/projects/astrazeneca/logos/LogoBadgeClean'),
            },
            qranio: {
                color: require('./dist/img/svgr/LogoQranioColor'),
                clean: require('./dist/img/svgr/LogoQranioClean'),
            },
        },
        firebase: {
            config: {
                apiKey: "AIzaSyDkkl5yZTQ29C7i07MItUqCFu6HZK8nAGE",
                authDomain: "app-qr-astrazeneca.firebaseapp.com",
                databaseURL: "https://app-qr-astrazeneca-default-rtdb.firebaseio.com",
                projectId: "app-qr-astrazeneca",
                storageBucket: "app-qr-astrazeneca.appspot.com",
                messagingSenderId: "813386324308",
                appId: "1:813386324308:web:22398f7513c5b777e090c1",
                measurementId: "G-NQQMYCCFDE"
            },
            vapid: {
                vapidKey: 'BBHNM8J4OK497ghPOK-w9F6qadIQ8vspR1Zl4Jg7xb8P7exitaHyqLccaDt38zhZXdeiG8c7C-6rzezZAmxUhz0'
            }
        },
        captcha: {
            type: 'invisible', // [checkbox / invisible]
            key: '6LfyuVgpAAAAAG4M7f56CPFVgjyJ9ssL-cPIQh0X',
            invisible_key: '6Le4slgpAAAAACS0gah8hKg-sCJ7CWvIDCpq2C5v',
        },
        login_method: {
            qrlogin: {
                enabled: true,
                type: 'email' // [email / username]
            },
            ad_azure: {
                enabled: true,
                scopes: ["profile", "User.Read"],
                style: 'button', // [button / link]
            },
            saml2: {
                enabled: false,
                style: 'button', // [button / link]
            },
            email: {
                login: {
                    enabled: false,
                    style: {
                        small_screen: 'button', // [form / button / link]
                        large_screen: 'form', // [form / button / link]
                    },
                    show_access_work_confirmation: false
                },
                signup: {
                    enabled: false,
                    display_button: {
                        intro: {
                            enabled: true,
                            style: {
                                small_screen: 'button', // [button / link]
                                large_screen: 'link', // [button / link]
                            }
                        },
                        login: {
                            enabled: true,
                            style: 'link', // [button / link]
                        }
                    },
                    have_country: true,
                    country_default: 'BR',
                    have_enterprise: false
                },
                forgot_password: {
                    enabled: false
                }
            },
            username: {
                login: {
                    enabled: false,
                    style: {
                        small_screen: 'button', // [form / button / link]
                        large_screen: 'form', // [form / button / link]
                    },
                    label: 'CPF', // [LOGIN / CPF / DRT]
                    show_access_work_confirmation: false
                },
                signup: {
                    enabled: false,
                    display_button: {
                        intro: {
                            enabled: true,
                            style: {
                                small_screen: 'button', // [button / link]
                                large_screen: 'link', // [button / link]
                            }
                        },
                        login: {
                            enabled: true,
                            style: 'link', // [button / link]
                        }
                    },
                    have_country: true,
                    country_default: 'BR',
                    have_enterprise: false
                },
                forgot_password: {
                    enabled: false
                }
            },
            password: {
                complex: false,
                min: 8
            },
            first_access: {
                enabled: false,
                code_verify: false,
                questions: false,
                number_questions: 5
            },
            complete_data: {
                form: {
                    cell_number: {
                        enabled: false,
                        required: false
                    },
                    city: {
                        enabled: false,
                        required: false
                    },
                    country: {
                        enabled: false,
                        required: false
                    },
                    email: {
                        enabled: false,
                        required: false,
                        domain: ["general"] // Array[general / domains]
                    },
                    birthdate: {
                        enabled: false,
                        required: false
                    },
                    state: {
                        enabled: false,
                        required: false
                    },
                    brand: {
                        enabled: false,
                        required: false
                    },
                    role: {
                        enabled: false,
                        required: false
                    },
                    role_family: {
                        enabled: false,
                        required: false
                    },
                    role_group: {
                        enabled: false,
                        required: false
                    },
                    position: {
                        enabled: false,
                        required: false
                    },
                    regional: {
                        enabled: false,
                        required: false
                    },
                    subarea: {
                        enabled: false,
                        required: false
                    },
                    board: {
                        enabled: false,
                        required: false
                    },
                    executive_board: {
                        enabled: false,
                        required: false
                    },
                    managing: {
                        enabled: false,
                        required: false
                    },
                    cost_center: {
                        enabled: false,
                        required: false
                    },
                    enterprise: {
                        enabled: false,
                        required: false
                    },
                }
            },
            have_alternative_code: false
        },
        intro: {
            logo: {
                small_screen: {
                    type: 'main-clean', // [app-icon, main-color, main-clean, group-color, group-clean, badge-color, badge-clean, qranio-color, qranio-clean]
                    size: 64
                },
                large_screen: {
                    type: 'main-color',
                    size: 80
                },
                footer: {
                    qranio: {
                        display: false,
                        type: 'qranio-color',
                        size: 24
                    },
                    group: {
                        display: true,
                        type: 'group-color',
                        size: 24
                    }
                }
            },
            type: 1, // [1 (com slider) / 2 (sem slider)]
            large_split_screen: false,
            vector_images: true,
            slider: [
                {
                    type: 'Course',
                    enabled: true,
                    text: 'Aqui você aprende de uma maneira divertida',
                },
                {
                    type: 'Ranking',
                    enabled: true,
                    text: 'Entre para o topo do ranking entre nossos usuários',
                },
                {
                    type: 'Feeds',
                    enabled: true,
                    text: 'Comente e interaja através de diversos conteúdos',
                },
                {
                    type: 'Scorm',
                    enabled: true,
                    text: 'Acompanhe todo o seu desenvolvimento',
                },
            ],
            show_popup: true,
            show_title: true,
            show_footer: true
        },
        layout: {
            theme: 'LIGHT',
            input_variant: 'outlined', // [outlined / filled / standard]
            char: {
                gender: 'random', // [female / male / random]
                color: 'random', // [black / white / random]
            },
            points: {
                neuros: false,
                qis: false
            },
            menu: {
                expanded: false,
                nav_order: ["MENU_HOME", "MENU_FEEDS", "MENU_LEARN", "MENU_PRIZES", "MENU_RANKING", "MENU_NOTIFICATIONS", "MENU_LIBRARY",
                    "MENU_MY_REDEEMS", "MENU_CODE_REDEEM", "MENU_MY_HISTORY", "MENU_MANAGER_PANEL", "MENU_FAQ", "MENU_SETTINGS"],
                nav_bottom_order: ["MENU_HOME", "MENU_FEEDS", "MENU_LEARN", "MENU_MY_HISTORY", "MENU_MORE"],
                logout_button: true,
                logo_footer: false
            },
            navbar: true,
            sidebar: {
                expanded: true
            },
            files: {
                download: false
            },
            videos: {
                download: false,
                picture_in_picture: true
            }
        },
        home: {
            empty_modules_visible: true,
            sidebar: true,
            sections: {
                highlight: {
                    enabled: false,
                    show_mobile: {
                        title: true,
                        text: true
                    }
                },
                knowmore: {
                    image_type: 'icon', // [play / image / icon]
                    image: '',
                    section_color: 'section-home',
                    icon: 'info_popup',
                    icon_color: 'white',
                    enabled: false
                },
                trail: {
                    image_type: 'play', // [play / image / icon]
                    image: 'trail',
                    section_color: 'section-play-trail',
                    icon: 'play_trail',
                    icon_color: 'white',
                    categories: 'courses', // [themes / courses]
                    mobile_grid: 'inline' // [inline / grid]
                },
                scorm: {
                    image_type: 'play', // [play / image / icon]
                    image: 'scorm',
                    section_color: 'section-play-scorm',
                    icon: 'play_scorm',
                    icon_color: 'white',
                    categories: 'courses', // [themes / courses]
                    mobile_grid: 'inline' // [inline / grid]
                },
                free: {
                    image_type: 'play', // [play / image / icon]
                    image: 'free',
                    section_color: 'section-play-free',
                    icon: 'play_free',
                    icon_color: 'white',
                    mobile_grid: 'inline' // [inline / grid]
                },
                classroom: {
                    image_type: 'play', // [play / image / icon]
                    image: 'classroom',
                    section_color: 'section-play-classroom',
                    icon: 'play_classroom',
                    icon_color: 'white'
                },
                exam: {
                    image_type: 'play', // [play / image / icon]
                    image: 'exam',
                    section_color: 'section-play-exam',
                    icon: 'play_exam',
                    icon_color: 'white'
                },
            },
            order: ["HIGHLIGHTS", "KNOWMORE", "PERIODIC_QUESTION", "TRAIL", "SCORM", "EXAM", "CLASSROOM_COURSE", "FREE"],
        },
        feeds: {
            enabled: true,
            cards: {
                date: true
            }
        },
        learn: {
            enabled: true,
            answer_double_click: true,
            order: ["PERIODIC_QUESTION", "TRAIL", "SCORM", "EXAM", "CLASSROOM_COURSE", "FREE", "VIRTUAL_LIBRARY"],
            trail: {
                list: 'courses', // [themes / courses]
                categories: {
                    grid: {
                        style: 'default', // [default / card]
                        size: 'large', // [small / large]
                        mobile: 'default', // [default / full]
                    },
                    stars: false,
                    steps: false,
                    workload: false,
                    progress: {
                        enabled: true,
                        type: 'circular', // [bar / circular]
                        label: true,
                        position: 'center' // [left / center / right]
                    }
                },
                vector_images: true,
                chars: true,
                type: 'step', // [stars / step]
                workload: false,
                best_view: false,
                scorm_open_alert: true,
                rating_comments: true,
                completed_message: false,
                stage: {
                    medal: true,
                    shadow: true,
                    stars: false,
                    stages: true,
                    stage_type: true
                },
                unified_stages: {
                    display: true,
                    expanded: 'auto' // [true, false, auto]
                },
                result_images: {
                    win: {
                        female1: require('./dist/img/banner/img_background_result_win_1_1.jpg'),
                        female2: require('./dist/img/banner/img_background_result_win_1_2.jpg'),
                        male1: require('./dist/img/banner/img_background_result_win_2_1.jpg'),
                        male2: require('./dist/img/banner/img_background_result_win_2_2.jpg')
                    },
                    lose: require('./dist/img/banner/img_background_result_lose.jpg')
                }
            },
            scorm: {
                list: 'themes', // [themes / courses]
                categories: {
                    grid: {
                        style: 'default', // [default / card]
                        size: 'small', // [small / large]
                        mobile: 'default', // [default / full]
                    },
                    progress: {
                        enabled: true,
                        type: 'circular', // [bar / circular]
                        label: true,
                        position: 'center' // [left / center / right]
                    }
                },
            },
            exam: {
                navigation: 'top', // [bottom / top]
                score: true
            },
            free: {
                categories: {
                    grid: {
                        style: 'default', // [default / card]
                        size: 'small', // [small / large]
                        mobile: 'default', // [default / full]
                    },
                },
            }
        },
        ranking: {
            flag: {
                enabled: true,
                type: "country", // [country / states]
            },
            filter: true,
            max_positions: 0 // [infinite = 0 / limit number (min = 3)]
        },
        library: {
            have_category: true
        },
        manager: {
            head_cards: {
                scorm: true,
                classroom: true,
                trail: true,
            },
            table_team_employees: true,
            table_team_activities: true,
            table_all_users: true
        },
        my_history: {
            enabled: true,
            head_cards: {
                last_login: true,
                ranking: false,
                courses_obligatory: true,
                courses_completed: true,
            },
            my_activities: {
                enabled: true,
                type_course: true,
                type: true,
                date_start: true,
                date_finish: true,
                workload: true,
                certificate_validity: false,
                do_until: false,
                obligatory: true
            }
        },
        faq: {
            expanded: true
        },
        settings: {
            edit_profile: {
                avatar: {
                    enabled: true
                },
                first_name: {
                    enabled: false,
                    required: false
                },
                last_name: {
                    enabled: false,
                    required: false
                }
            }
        }
    },

    YAMAHA: {
        app: {
            name: 'Yamaha',
            name_project: 'YAMAHA',
            description: 'Aplicativo',
            favicon: '/favicon/yamaha/favicon.ico',
            code: '_p28',
        },
        logos: {
            app_icon: require('./dist/img/projects/yamaha/logos/LogoAppIcon'),
            main: {
                color: require('./dist/img/projects/yamaha/logos/LogoMainColor'),
                clean: require('./dist/img/projects/yamaha/logos/LogoMainClean'),
            },
            group: {
                color: require('./dist/img/svgr/LogoQranioColor'),
                clean: require('./dist/img/svgr/LogoQranioClean'),
            },
            badge: {
                color: require('./dist/img/projects/yamaha/logos/LogoBadgeColor'),
                clean: require('./dist/img/projects/yamaha/logos/LogoBadgeClean'),
            },
            qranio: {
                color: require('./dist/img/svgr/LogoQranioColor'),
                clean: require('./dist/img/svgr/LogoQranioClean'),
            },
        },
        firebase: {
            config: {
                apiKey: 'AIzaSyBAodKxN4BOGM0Uvo1MrPKACgmPcG0a7AA',
                authDomain: 'app-qr-yamaha.firebaseapp.com',
                databaseURL: 'https://app-qr-yamaha-default-rtdb.firebaseio.com',
                projectId: 'app-qr-yamaha',
                storageBucket: 'app-qr-yamaha.appspot.com',
                messagingSenderId: '17526691834',
                appId: '1:17526691834:web:2ded3abf912bca79c5f643',
                measurementId: 'G-BCPDE8V68B'
            },
            vapid: {
                vapidKey: 'BI7mZ542_lL7uCPYHJbxB2pbUtyp0oXN8iqZPP2Yein8ySRxab6Phhln3yY7XfZQ-DnZQ7pm2gjnqrjJkPNcHhM'
            }
        },
        captcha: {
            type: 'invisible', // [checkbox / invisible]
            key: '6LfjCuUpAAAAANgX22Pa-A3KE540IZCkmMvmYtIR',
            invisible_key: '6Lf-CuUpAAAAAHZJjove0HqEBejt2ruubiJ_m3bh',
        },
        login_method: {
            qrlogin: {
                enabled: true,
                type: 'email' // [email / username]
            },
            ad_azure: {
                enabled: false,
                scopes: ["profile", "User.Read"],
                style: 'button', // [button / link]
            },
            saml2: {
                enabled: false,
                style: 'button', // [button / link]
            },
            email: {
                login: {
                    enabled: false,
                    style: {
                        small_screen: 'button', // [form / button / link]
                        large_screen: 'form', // [form / button / link]
                    },
                    show_access_work_confirmation: false
                },
                signup: {
                    enabled: false,
                    display_button: {
                        intro: {
                            enabled: false,
                            style: {
                                small_screen: 'button', // [button / link]
                                large_screen: 'link', // [button / link]
                            }
                        },
                        login: {
                            enabled: false,
                            style: 'link', // [button / link]
                        }
                    },
                    have_country: true,
                    country_default: 'BR',
                    have_enterprise: false
                },
                forgot_password: {
                    enabled: true
                }
            },
            username: {
                login: {
                    enabled: false,
                    style: {
                        small_screen: 'button', // [form / button / link]
                        large_screen: 'form', // [form / button / link]
                    },
                    label: 'CPF', // [LOGIN / CPF / DRT]
                    show_access_work_confirmation: false
                },
                signup: {
                    enabled: false,
                    display_button: {
                        intro: {
                            enabled: false,
                            style: {
                                small_screen: 'button', // [button / link]
                                large_screen: 'link', // [button / link]
                            }
                        },
                        login: {
                            enabled: false,
                            style: 'link', // [button / link]
                        }
                    },
                    have_country: true,
                    country_default: 'BR',
                    have_enterprise: false
                },
                forgot_password: {
                    enabled: true
                }
            },
            password: {
                complex: false,
                min: 8
            },
            first_access: {
                enabled: false,
                code_verify: false,
                questions: false,
                number_questions: 5
            },
            complete_data: {
                form: {
                    cell_number: {
                        enabled: false,
                        required: false
                    },
                    city: {
                        enabled: false,
                        required: false
                    },
                    country: {
                        enabled: false,
                        required: false
                    },
                    email: {
                        enabled: false,
                        required: false,
                        domain: ["general"] // Array[general / domains]
                    },
                    birthdate: {
                        enabled: false,
                        required: false
                    },
                    state: {
                        enabled: false,
                        required: false
                    },
                    brand: {
                        enabled: false,
                        required: false
                    },
                    role: {
                        enabled: false,
                        required: false
                    },
                    role_family: {
                        enabled: false,
                        required: false
                    },
                    role_group: {
                        enabled: false,
                        required: false
                    },
                    position: {
                        enabled: false,
                        required: false
                    },
                    regional: {
                        enabled: false,
                        required: false
                    },
                    subarea: {
                        enabled: false,
                        required: false
                    },
                    board: {
                        enabled: false,
                        required: false
                    },
                    executive_board: {
                        enabled: false,
                        required: false
                    },
                    managing: {
                        enabled: false,
                        required: false
                    },
                    cost_center: {
                        enabled: false,
                        required: false
                    },
                    enterprise: {
                        enabled: false,
                        required: false
                    },
                }
            },
            have_alternative_code: false
        },
        intro: {
            logo: {
                small_screen: {
                    type: 'main-color', // [app-icon, main-color, main-clean, group-color, group-clean, badge-color, badge-clean, qranio-color, qranio-clean]
                    size: 64
                },
                large_screen: {
                    type: 'main-color',
                    size: 80
                },
                footer: {
                    qranio: {
                        display: false,
                        type: 'qranio-color',
                        size: 24
                    },
                    group: {
                        display: false,
                        type: 'group-color',
                        size: 24
                    }
                }
            },
            type: 2, // [1 (com slider) / 2 (sem slider)]
            large_split_screen: false,
            vector_images: true,
            slider: [
                {
                    type: 'Course',
                    enabled: true,
                    text: 'Aqui você aprende de uma maneira divertida',
                },
                {
                    type: 'Duel',
                    enabled: true,
                    text: 'Tenha a liberdade de duelar com seus amigos',
                },
                {
                    type: 'Onboarding',
                    enabled: true,
                    text: 'Ganhe reconhecimento',
                },
                {
                    type: 'Ranking',
                    enabled: true,
                    text: 'Entre para o topo do ranking entre nossos usuários',
                },
                {
                    type: 'Prizes',
                    enabled: true,
                    text: 'Troque prêmios através de moedas adquiridas com seu conhecimento',
                },
                {
                    type: 'Feeds',
                    enabled: true,
                    text: 'Comente e interaja através de diversos conteúdos',
                },
                {
                    type: 'Scorm',
                    enabled: true,
                    text: 'Acompanhe todo o seu desenvolvimento',
                },
            ],
            show_popup: false,
            show_title: false,
            show_footer: false
        },
        layout: {
            theme: 'DARK',
            input_variant: 'outlined', // [outlined / filled / standard]
            char: {
                gender: 'random', // [female / male / random]
                color: 'random', // [black / white / random]
            },
            points: {
                neuros: true,
                qis: true
            },
            menu: {
                expanded: false,
                nav_order: ["MENU_HOME", "MENU_ACHIEVEMENTS", "MENU_PRIZES", "MENU_MY_REDEEMS", "MENU_FAQ", "MENU_SETTINGS"],
                nav_bottom_order: ["MENU_HOME", "MENU_ACHIEVEMENTS", "MENU_PRIZES", "MENU_MORE"],
                logout_button: false,
                logo_footer: false
            },
            navbar: true,
            sidebar: {
                expanded: false
            },
            files: {
                download: false
            },
            videos: {
                download: false,
                picture_in_picture: true
            }
        },
        home: {
            empty_modules_visible: true,
            sidebar: false,
            sections: {
                highlight: {
                    enabled: false,
                    show_mobile: {
                        title: true,
                        text: true
                    }
                },
                knowmore: {
                    image_type: 'icon', // [play / image / icon]
                    image: '',
                    section_color: 'section-home',
                    icon: 'info_popup',
                    icon_color: 'white',
                    enabled: false
                },
                trail: {
                    image_type: 'icon', // [play / image / icon]
                    image: 'trail',
                    section_color: '',
                    icon: 'play_trail',
                    icon_color: 'primary',
                    categories: 'courses', // [themes / courses]
                    mobile_grid: 'inline' // [inline / grid]
                },
                scorm: {
                    image_type: 'play', // [play / image / icon]
                    image: 'scorm',
                    section_color: 'section-play-scorm',
                    icon: 'play_scorm',
                    icon_color: 'white',
                    categories: 'courses', // [themes / courses]
                    mobile_grid: 'grid' // [inline / grid]
                },
                free: {
                    image_type: 'play', // [play / image / icon]
                    image: 'free',
                    section_color: 'section-play-free',
                    icon: 'play_free',
                    icon_color: 'white',
                    mobile_grid: 'grid' // [inline / grid]
                },
                classroom: {
                    image_type: 'play', // [play / image / icon]
                    image: 'classroom',
                    section_color: 'section-play-classroom',
                    icon: 'play_classroom',
                    icon_color: 'white'
                },
                exam: {
                    image_type: 'play', // [play / image / icon]
                    image: 'exam',
                    section_color: 'section-play-exam',
                    icon: 'play_exam',
                    icon_color: 'white'
                },
            },
            order: ["HIGHLIGHTS", "KNOWMORE", "PERIODIC_QUESTION", "TRAIL", "SCORM", "EXAM", "CLASSROOM_COURSE", "FREE"],
        },
        feeds: {
            enabled: false,
            cards: {
                date: false
            }
        },
        learn: {
            enabled: false,
            answer_double_click: true,
            order: ["PERIODIC_QUESTION", "TRAIL", "SCORM", "EXAM", "CLASSROOM_COURSE", "FREE", "VIRTUAL_LIBRARY"],
            trail: {
                list: 'courses', // [themes / courses]
                categories: {
                    grid: {
                        style: 'card', // [default / card]
                        size: 'large', // [small / large]
                        mobile: 'full', // [default / full]
                    },
                    stars: true,
                    steps: true,
                    workload: true,
                    progress: {
                        enabled: true,
                        type: 'bar', // [bar / circular]
                        label: false,
                        position: 'right' // [left / center / right]
                    }
                },
                vector_images: false,
                chars: false,
                type: 'stars', // [stars / step]
                workload: true,
                best_view: false,
                scorm_open_alert: true,
                rating_comments: true,
                completed_message: true,
                stage: {
                    medal: true,
                    shadow: true,
                    stars: true,
                    stages: true,
                    stage_type: true
                },
                unified_stages: {
                    display: true,
                    expanded: 'auto' // [true, false, auto]
                },
                result_images: {
                    win: {
                        female1: require('./dist/img/projects/yamaha/banner/img_background_result_win_1_1.jpg'),
                        female2: require('./dist/img/projects/yamaha/banner/img_background_result_win_1_1.jpg'),
                        male1: require('./dist/img/projects/yamaha/banner/img_background_result_win_1_1.jpg'),
                        male2: require('./dist/img/projects/yamaha/banner/img_background_result_win_1_1.jpg')
                    },
                    lose: require('./dist/img/projects/yamaha/banner/img_background_result_lose.jpg')
                }
            },
            scorm: {
                list: 'themes', // [themes / courses]
                categories: {
                    grid: {
                        style: 'default', // [default / card]
                        size: 'small', // [small / large]
                        mobile: 'default', // [default / full]
                    },
                    progress: {
                        enabled: true,
                        type: 'circular', // [bar / circular]
                        label: true,
                        position: 'center' // [left / center / right]
                    }
                },
            },
            exam: {
                navigation: 'top', // [bottom / top]
                score: true
            },
            free: {
                categories: {
                    grid: {
                        style: 'card', // [default / card]
                        size: 'large', // [small / large]
                        mobile: 'full', // [default / full]
                    },
                },
            }
        },
        ranking: {
            flag: {
                enabled: true,
                type: "country", // [country / states]
            },
            filter: true,
            max_positions: 0 // [infinite = 0 / limit number (min = 3)]
        },
        library: {
            have_category: true
        },
        manager: {
            head_cards: {
                scorm: true,
                classroom: true,
                trail: true,
            },
            table_team_employees: true,
            table_team_activities: true,
            table_all_users: true
        },
        my_history: {
            enabled: false,
            head_cards: {
                last_login: false,
                ranking: false,
                courses_obligatory: false,
                courses_completed: true,
            },
            my_activities: {
                enabled: true,
                type_course: false,
                type: false,
                date_start: false,
                date_finish: false,
                workload: false,
                certificate_validity: false,
                do_until: false,
                obligatory: false
            }
        },
        faq: {
            expanded: true
        },
        settings: {
            edit_profile: {
                avatar: {
                    enabled: true
                },
                first_name: {
                    enabled: true,
                    required: true
                },
                last_name: {
                    enabled: true,
                    required: true
                }
            }
        }
    },

    INPASA: {
        app: {
            name: 'Inpasa',
            name_project: 'INPASA',
            description: 'Aplicativo',
            favicon: '/favicon/inpasa/favicon.ico',
            code: '_p29',
        },
        logos: {
            app_icon: require('./dist/img/projects/inpasa/logos/LogoAppIcon'),
            main: {
                color: require('./dist/img/projects/inpasa/logos/LogoMainColor'),
                clean: require('./dist/img/projects/inpasa/logos/LogoMainClean'),
            },
            group: {
                color: require('./dist/img/svgr/LogoQranioColor'),
                clean: require('./dist/img/svgr/LogoQranioClean'),
            },
            badge: {
                color: require('./dist/img/projects/inpasa/logos/LogoBadgeColor'),
                clean: require('./dist/img/projects/inpasa/logos/LogoBadgeClean'),
            },
            qranio: {
                color: require('./dist/img/svgr/LogoQranioColor'),
                clean: require('./dist/img/svgr/LogoQranioClean'),
            },
        },
        firebase: {
            config: {
                apiKey: 'AIzaSyBBkI7i08BWx5KSwyIWXIEwzM1uTwoGyrg',
                authDomain: 'app-qr-inpasa.firebaseapp.com',
                databaseURL: 'https://app-qr-inpasa-default-rtdb.firebaseio.com',
                projectId: 'app-qr-inpasa',
                storageBucket: 'app-qr-inpasa.appspot.com',
                messagingSenderId: '480160118183',
                appId: '1:480160118183:web:a60d291172f2d690483282',
                measurementId: 'G-K5N54SW8VR'
            },
            vapid: {
                vapidKey: 'BJnhVITx1Hkp5pTGkkU5q15mtwIa2IcQRY0oTmzaNFJmBhwESwcItd7DCF7R4sYsSmbCwaaVMcP15xdqep3-NeE'
            }
        },
        captcha: {
            type: 'invisible', // [checkbox / invisible]
            key: '6LeiKCYqAAAAAJ_HL_awjmxEpUzyHGvzoCYkkRRE',
            invisible_key: '6LcWKSYqAAAAAEuArBGGY_J2xBQLcMS5zRHONhD8',
        },
        login_method: {
            qrlogin: {
                enabled: true,
                type: 'email' // [email / username]
            },
            ad_azure: {
                enabled: false,
                scopes: ["profile", "User.Read"],
                style: 'button', // [button / link]
            },
            saml2: {
                enabled: false,
                style: 'button', // [button / link]
            },
            email: {
                login: {
                    enabled: true,
                    style: {
                        small_screen: 'button', // [form / button / link]
                        large_screen: 'form', // [form / button / link]
                    },
                    show_access_work_confirmation: false
                },
                signup: {
                    enabled: false,
                    display_button: {
                        intro: {
                            enabled: true,
                            style: {
                                small_screen: 'button', // [button / link]
                                large_screen: 'link', // [button / link]
                            }
                        },
                        login: {
                            enabled: true,
                            style: 'link', // [button / link]
                        }
                    },
                    have_country: true,
                    country_default: 'BR',
                    have_enterprise: false
                },
                forgot_password: {
                    enabled: true
                }
            },
            username: {
                login: {
                    enabled: false,
                    style: {
                        small_screen: 'button', // [form / button / link]
                        large_screen: 'form', // [form / button / link]
                    },
                    label: 'CPF', // [LOGIN / CPF / DRT]
                    show_access_work_confirmation: false
                },
                signup: {
                    enabled: false,
                    display_button: {
                        intro: {
                            enabled: true,
                            style: {
                                small_screen: 'button', // [button / link]
                                large_screen: 'link', // [button / link]
                            }
                        },
                        login: {
                            enabled: true,
                            style: 'link', // [button / link]
                        }
                    },
                    have_country: true,
                    country_default: 'BR',
                    have_enterprise: false
                },
                forgot_password: {
                    enabled: true
                }
            },
            password: {
                complex: true,
                min: 10
            },
            first_access: {
                enabled: false,
                code_verify: false,
                questions: false,
                number_questions: 5
            },
            complete_data: {
                form: {
                    cell_number: {
                        enabled: false,
                        required: false
                    },
                    city: {
                        enabled: false,
                        required: false
                    },
                    country: {
                        enabled: false,
                        required: false
                    },
                    email: {
                        enabled: false,
                        required: false,
                        domain: ["general"] // Array[general / domains]
                    },
                    birthdate: {
                        enabled: false,
                        required: false
                    },
                    state: {
                        enabled: false,
                        required: false
                    },
                    brand: {
                        enabled: false,
                        required: false
                    },
                    role: {
                        enabled: false,
                        required: false
                    },
                    role_family: {
                        enabled: false,
                        required: false
                    },
                    role_group: {
                        enabled: false,
                        required: false
                    },
                    position: {
                        enabled: false,
                        required: false
                    },
                    regional: {
                        enabled: false,
                        required: false
                    },
                    subarea: {
                        enabled: false,
                        required: false
                    },
                    board: {
                        enabled: false,
                        required: false
                    },
                    executive_board: {
                        enabled: false,
                        required: false
                    },
                    managing: {
                        enabled: false,
                        required: false
                    },
                    cost_center: {
                        enabled: false,
                        required: false
                    },
                    enterprise: {
                        enabled: false,
                        required: false
                    },
                }
            },
            have_alternative_code: false
        },
        intro: {
            logo: {
                small_screen: {
                    type: 'main-clean', // [app-icon, main-color, main-clean, group-color, group-clean, badge-color, badge-clean, qranio-color, qranio-clean]
                    size: 64
                },
                large_screen: {
                    type: 'main-color',
                    size: 80
                },
                footer: {
                    qranio: {
                        display: true,
                        type: 'qranio-color',
                        size: 24
                    },
                    group: {
                        display: false,
                        type: 'group-color',
                        size: 24
                    }
                }
            },
            type: 1, // [1 (com slider) / 2 (sem slider)]
            large_split_screen: false,
            vector_images: true,
            slider: [
                {
                    type: 'Course',
                    enabled: true,
                    text: 'Aqui você aprende de uma maneira divertida',
                },
                {
                    type: 'Duel',
                    enabled: false,
                    text: 'Tenha a liberdade de duelar com seus amigos',
                },
                {
                    type: 'Onboarding',
                    enabled: true,
                    text: 'Ganhe reconhecimento',
                },
                {
                    type: 'Ranking',
                    enabled: true,
                    text: 'Entre para o topo do ranking entre nossos usuários',
                },
                {
                    type: 'Prizes',
                    enabled: false,
                    text: 'Troque prêmios através de moedas adquiridas com seu conhecimento',
                },
                {
                    type: 'Feeds',
                    enabled: true,
                    text: 'Comente e interaja através de diversos conteúdos',
                },
                {
                    type: 'Scorm',
                    enabled: true,
                    text: 'Acompanhe todo o seu desenvolvimento',
                },
            ],
            show_popup: true,
            show_title: true,
            show_footer: false
        },
        layout: {
            theme: 'LIGHT',
            input_variant: 'outlined', // [outlined / filled / standard]
            char: {
                gender: 'random', // [female / male / random]
                color: 'random', // [black / white / random]
            },
            points: {
                neuros: true,
                qis: true
            },
            menu: {
                expanded: false,
                nav_order: ["MENU_HOME", "MENU_FEEDS", "MENU_LEARN", "MENU_PRIZES", "MENU_RANKING", "MENU_NOTIFICATIONS", "MENU_LIBRARY",
                    "MENU_MY_REDEEMS", "MENU_CODE_REDEEM", "MENU_MY_HISTORY", "MENU_MANAGER_PANEL", "MENU_FAQ", "MENU_SETTINGS"],
                nav_bottom_order: ["MENU_HOME", "MENU_FEEDS", "MENU_LEARN", "MENU_MY_HISTORY", "MENU_MORE"],
                logout_button: true,
                logo_footer: false
            },
            navbar: true,
            sidebar: {
                expanded: true
            },
            files: {
                download: false
            },
            videos: {
                download: false,
                picture_in_picture: true
            }
        },
        home: {
            empty_modules_visible: true,
            sidebar: true,
            sections: {
                highlight: {
                    enabled: true,
                    show_mobile: {
                        title: true,
                        text: true
                    }
                },
                knowmore: {
                    image_type: 'icon', // [play / image / icon]
                    image: '',
                    section_color: 'section-home',
                    icon: 'info_popup',
                    icon_color: 'white',
                    enabled: true
                },
                trail: {
                    image_type: 'play', // [play / image / icon]
                    image: 'trail',
                    section_color: 'section-play-trail',
                    icon: 'play_trail',
                    icon_color: 'white',
                    categories: 'courses', // [themes / courses]
                    mobile_grid: 'inline' // [inline / grid]
                },
                scorm: {
                    image_type: 'play', // [play / image / icon]
                    image: 'scorm',
                    section_color: 'section-play-scorm',
                    icon: 'play_scorm',
                    icon_color: 'white',
                    categories: 'courses', // [themes / courses]
                    mobile_grid: 'inline' // [inline / grid]
                },
                free: {
                    image_type: 'play', // [play / image / icon]
                    image: 'free',
                    section_color: 'section-play-free',
                    icon: 'play_free',
                    icon_color: 'white',
                    mobile_grid: 'inline' // [inline / grid]
                },
                classroom: {
                    image_type: 'play', // [play / image / icon]
                    image: 'classroom',
                    section_color: 'section-play-classroom',
                    icon: 'play_classroom',
                    icon_color: 'white'
                },
                exam: {
                    image_type: 'play', // [play / image / icon]
                    image: 'exam',
                    section_color: 'section-play-exam',
                    icon: 'play_exam',
                    icon_color: 'white'
                },
            },
            knowmore: true,
            trail: 'courses', // [themes / courses]
            scorm: 'courses', // [themes / courses]
            order: ["HIGHLIGHTS", "TRAIL", "EXAM", "CLASSROOM_COURSE", "FREE", "KNOWMORE"],
        },
        feeds: {
            enabled: true,
            cards: {
                date: true
            }
        },
        learn: {
            enabled: true,
            answer_double_click: true,
            order: ["TRAIL", "EXAM", "CLASSROOM_COURSE", "VIRTUAL_LIBRARY", "PERIODIC_QUESTION", "FREE"],
            trail: {
                list: 'themes', // [themes / courses]
                categories: {
                    grid: {
                        style: 'default', // [default / card]
                        size: 'small', // [small / large]
                        mobile: 'default', // [default / full]
                    },
                    stars: true,
                    steps: false,
                    workload: false,
                    progress: {
                        enabled: false,
                        type: 'bar', // [bar / circular]
                        label: true,
                        position: 'center' // [left / center / right]
                    }
                },
                vector_images: true,
                chars: true,
                type: 'stars', // [stars / step]
                workload: false,
                best_view: false,
                scorm_open_alert: true,
                rating_comments: true,
                completed_message: false,
                stage: {
                    medal: true,
                    shadow: true,
                    stars: true,
                    stages: false,
                    stage_type: true
                },
                unified_stages: {
                    display: true,
                    expanded: 'auto' // [true, false, auto]
                },
                result_images: {
                    win: {
                        female1: require('./dist/img/banner/img_background_result_win_1_1.jpg'),
                        female2: require('./dist/img/banner/img_background_result_win_1_2.jpg'),
                        male1: require('./dist/img/banner/img_background_result_win_2_1.jpg'),
                        male2: require('./dist/img/banner/img_background_result_win_2_2.jpg')
                    },
                    lose: require('./dist/img/banner/img_background_result_lose.jpg')
                }
            },
            scorm: {
                list: 'themes', // [themes / courses]
                categories: {
                    grid: {
                        style: 'default', // [default / card]
                        size: 'small', // [small / large]
                        mobile: 'default', // [default / full]
                    },
                    progress: {
                        enabled: true,
                        type: 'circular', // [bar / circular]
                        label: true,
                        position: 'center' // [left / center / right]
                    }
                },
            },
            exam: {
                navigation: 'top', // [bottom / top]
                score: true
            },
            free: {
                categories: {
                    grid: {
                        style: 'default', // [default / card]
                        size: 'small', // [small / large]
                        mobile: 'default', // [default / full]
                    },
                },
            }
        },
        ranking: {
            flag: {
                enabled: true,
                type: "country", // [country / states]
            },
            filter: true,
            max_positions: 0 // [infinite = 0 / limit number (min = 3)]
        },
        library: {
            have_category: true
        },
        manager: {
            head_cards: {
                scorm: true,
                classroom: true,
                trail: true,
            },
            table_team_employees: true,
            table_team_activities: true,
            table_all_users: true
        },
        my_history: {
            enabled: true,
            head_cards: {
                last_login: true,
                ranking: true,
                courses_obligatory: true,
                courses_completed: true,
            },
            my_activities: {
                enabled: true,
                type_course: true,
                type: true,
                date_start: true,
                date_finish: true,
                workload: true,
                certificate_validity: true,
                do_until: true,
                obligatory: true
            }
        },
        faq: {
            expanded: true
        },
        settings: {
            edit_profile: {
                avatar: {
                    enabled: true
                },
                first_name: {
                    enabled: false,
                    required: false
                },
                last_name: {
                    enabled: false,
                    required: false
                }
            }
        }
    },

    AMIGAO: {
        app: {
            name: 'Grupo Amigão',
            name_project: 'AMIGAO',
            description: 'Aplicativo Grupo Amigão',
            favicon: '/favicon/amigao/favicon.ico',
            code: '_p30',
        },
        logos: {
            app_icon: require('./dist/img/projects/amigao/logos/LogoAppIcon'),
            main: {
                color: require('./dist/img/projects/amigao/logos/LogoMainColor'),
                clean: require('./dist/img/projects/amigao/logos/LogoMainClean'),
            },
            group: {
                color: require('./dist/img/svgr/LogoQranioColor'),
                clean: require('./dist/img/svgr/LogoQranioClean'),
            },
            badge: {
                color: require('./dist/img/svgr/LogoQranioColor'),
                clean: require('./dist/img/svgr/LogoQranioClean'),
            },
            qranio: {
                color: require('./dist/img/svgr/LogoQranioColor'),
                clean: require('./dist/img/svgr/LogoQranioClean'),
            },
        },
        firebase: {
            config: {
                apiKey: 'AIzaSyC1hJPJxSqP9zsGqZTN46MMiRzvd11r3-w',
                authDomain: 'app-qr-amigao.firebaseapp.com',
                databaseURL: 'https://app-qr-amigao-default-rtdb.firebaseio.com',
                projectId: 'app-qr-amigao',
                storageBucket: 'app-qr-amigao.appspot.com',
                messagingSenderId: '90555530703',
                appId: '1:90555530703:web:84efb1ff8ef5ace9aa66ba',
                measurementId: 'G-GEMZ218667'
            },
            vapid: {
                vapidKey: 'BCOwEWDXh_5UDTulMKXiC_3rnPK3x6uxGN4DMu0_72T4cUY44Q39t1WBZwlTXicytkzjstPFbmkM-7j8o8rtGQI'
            }
        },
        captcha: {
            type: 'invisible', // [checkbox / invisible]
            key: '6LdTs1UqAAAAAPOLoSwtVdhWNqN_8AZLwFFIVDaN',
            invisible_key: '6Lczs1UqAAAAAOcuR9LRPRA0aNUPelvzMgzoAHwN',
        },
        login_method: {
            qrlogin: {
                enabled: true,
                type: 'email' // [email / username]
            },
            ad_azure: {
                enabled: false,
                scopes: ["profile", "User.Read"],
                style: 'button', // [button / link]
            },
            saml2: {
                enabled: false,
                style: 'button', // [button / link]
            },
            email: {
                login: {
                    enabled: false,
                    style: {
                        small_screen: 'button', // [form / button / link]
                        large_screen: 'form', // [form / button / link]
                    },
                    show_access_work_confirmation: false
                },
                signup: {
                    enabled: false,
                    display_button: {
                        intro: {
                            enabled: true,
                            style: {
                                small_screen: 'button', // [button / link]
                                large_screen: 'link', // [button / link]
                            }
                        },
                        login: {
                            enabled: true,
                            style: 'link', // [button / link]
                        }
                    },
                    have_country: true,
                    country_default: 'BR',
                    have_enterprise: false
                },
                forgot_password: {
                    enabled: false
                }
            },
            username: {
                login: {
                    enabled: true,
                    style: {
                        small_screen: 'button', // [form / button / link]
                        large_screen: 'form', // [form / button / link]
                    },
                    label: 'CPF', // [LOGIN / CPF / DRT]
                    show_access_work_confirmation: false
                },
                signup: {
                    enabled: false,
                    display_button: {
                        intro: {
                            enabled: true,
                            style: {
                                small_screen: 'button', // [button / link]
                                large_screen: 'link', // [button / link]
                            }
                        },
                        login: {
                            enabled: true,
                            style: 'link', // [button / link]
                        }
                    },
                    have_country: true,
                    country_default: 'BR',
                    have_enterprise: false
                },
                forgot_password: {
                    enabled: true
                }
            },
            password: {
                complex: false,
                min: 8
            },
            first_access: {
                enabled: false,
                code_verify: false,
                questions: false,
                number_questions: 5
            },
            complete_data: {
                form: {
                    cell_number: {
                        enabled: false,
                        required: false
                    },
                    city: {
                        enabled: false,
                        required: false
                    },
                    country: {
                        enabled: false,
                        required: false
                    },
                    email: {
                        enabled: false,
                        required: false,
                        domain: ["general"] // Array[general / domains]
                    },
                    birthdate: {
                        enabled: false,
                        required: false
                    },
                    state: {
                        enabled: false,
                        required: false
                    },
                    brand: {
                        enabled: false,
                        required: false
                    },
                    role: {
                        enabled: false,
                        required: false
                    },
                    role_family: {
                        enabled: false,
                        required: false
                    },
                    role_group: {
                        enabled: false,
                        required: false
                    },
                    position: {
                        enabled: false,
                        required: false
                    },
                    regional: {
                        enabled: false,
                        required: false
                    },
                    subarea: {
                        enabled: false,
                        required: false
                    },
                    board: {
                        enabled: false,
                        required: false
                    },
                    executive_board: {
                        enabled: false,
                        required: false
                    },
                    managing: {
                        enabled: false,
                        required: false
                    },
                    cost_center: {
                        enabled: false,
                        required: false
                    },
                    enterprise: {
                        enabled: false,
                        required: false
                    },
                }
            },
            have_alternative_code: false
        },
        intro: {
            logo: {
                small_screen: {
                    type: 'main-clean', // [app-icon, main-color, main-clean, group-color, group-clean, badge-color, badge-clean, qranio-color, qranio-clean]
                    size: 64
                },
                large_screen: {
                    type: 'main-color',
                    size: 80
                },
                footer: {
                    qranio: {
                        display: true,
                        type: 'qranio-color',
                        size: 24
                    },
                    group: {
                        display: false,
                        type: 'group-color',
                        size: 24
                    }
                }
            },
            type: 1, // [1 (com slider) / 2 (sem slider)]
            large_split_screen: false,
            vector_images: true,
            slider: [
                {
                    type: 'Course',
                    enabled: true,
                    text: 'Aqui você aprende de uma maneira divertida',
                },
                {
                    type: 'Duel',
                    enabled: false,
                    text: 'Tenha a liberdade de duelar com seus amigos',
                },
                {
                    type: 'Onboarding',
                    enabled: true,
                    text: 'Ganhe reconhecimento',
                },
                {
                    type: 'Ranking',
                    enabled: true,
                    text: 'Entre para o topo do ranking entre nossos usuários',
                },
                {
                    type: 'Prizes',
                    enabled: false,
                    text: 'Troque prêmios através de moedas adquiridas com seu conhecimento',
                },
                {
                    type: 'Feeds',
                    enabled: true,
                    text: 'Comente e interaja através de diversos conteúdos',
                },
                {
                    type: 'Scorm',
                    enabled: true,
                    text: 'Acompanhe todo o seu desenvolvimento',
                },
            ],
            show_popup: true,
            show_title: true,
            show_footer: false
        },
        layout: {
            theme: 'DARK',
            input_variant: 'outlined', // [outlined / filled / standard]
            char: {
                gender: 'random', // [female / male / random]
                color: 'random', // [black / white / random]
            },
            points: {
                neuros: true,
                qis: true
            },
            menu: {
                expanded: false,
                nav_order: ["MENU_HOME", "MENU_FEEDS", "MENU_LEARN", "MENU_PRIZES", "MENU_RANKING", "MENU_NOTIFICATIONS", "MENU_LIBRARY",
                    "MENU_MY_REDEEMS", "MENU_CODE_REDEEM", "MENU_MY_HISTORY", "MENU_MANAGER_PANEL", "MENU_FAQ", "MENU_SETTINGS"],
                nav_bottom_order: ["MENU_HOME", "MENU_FEEDS", "MENU_LEARN", "MENU_MY_HISTORY", "MENU_MORE"],
                logout_button: true,
                logo_footer: false
            },
            navbar: true,
            sidebar: {
                expanded: true
            },
            files: {
                download: false
            },
            videos: {
                download: false,
                picture_in_picture: true
            }
        },
        home: {
            empty_modules_visible: false,
            sidebar: true,
            sections: {
                highlight: {
                    enabled: true,
                    show_mobile: {
                        title: true,
                        text: true
                    }
                },
                knowmore: {
                    image_type: 'icon', // [play / image / icon]
                    image: '',
                    section_color: 'section-home',
                    icon: 'info_popup',
                    icon_color: 'white',
                    enabled: true
                },
                trail: {
                    image_type: 'play', // [play / image / icon]
                    image: 'trail',
                    section_color: 'section-play-trail',
                    icon: 'play_trail',
                    icon_color: 'white',
                    categories: 'courses', // [themes / courses]
                    mobile_grid: 'inline' // [inline / grid]
                },
                scorm: {
                    image_type: 'play', // [play / image / icon]
                    image: 'scorm',
                    section_color: 'section-play-scorm',
                    icon: 'play_scorm',
                    icon_color: 'white',
                    categories: 'courses', // [themes / courses]
                    mobile_grid: 'inline' // [inline / grid]
                },
                free: {
                    image_type: 'play', // [play / image / icon]
                    image: 'free',
                    section_color: 'section-play-free',
                    icon: 'play_free',
                    icon_color: 'white',
                    mobile_grid: 'inline' // [inline / grid]
                },
                classroom: {
                    image_type: 'play', // [play / image / icon]
                    image: 'classroom',
                    section_color: 'section-play-classroom',
                    icon: 'play_classroom',
                    icon_color: 'white'
                },
                exam: {
                    image_type: 'play', // [play / image / icon]
                    image: 'exam',
                    section_color: 'section-play-exam',
                    icon: 'play_exam',
                    icon_color: 'white'
                },
            },
            order: ["HIGHLIGHTS", "TRAIL", "EXAM", "CLASSROOM_COURSE", "FREE", "KNOWMORE"],
        },
        feeds: {
            enabled: true,
            cards: {
                date: true
            }
        },
        learn: {
            enabled: true,
            answer_double_click: true,
            order: ["TRAIL", "EXAM", "CLASSROOM_COURSE", "VIRTUAL_LIBRARY", "PERIODIC_QUESTION", "FREE"],
            trail: {
                list: 'themes', // [themes / courses]
                categories: {
                    grid: {
                        style: 'default', // [default / card]
                        size: 'large', // [small / large]
                        mobile: 'default', // [default / full]
                    },
                    stars: true,
                    steps: false,
                    workload: false,
                    progress: {
                        enabled: false,
                        type: 'bar', // [bar / circular]
                        label: true,
                        position: 'center' // [left / center / right]
                    }
                },
                vector_images: true,
                chars: true,
                type: 'stars', // [stars / step]
                workload: true,
                best_view: true,
                scorm_open_alert: true,
                rating_comments: true,
                completed_message: false,
                stage: {
                    medal: true,
                    shadow: true,
                    stars: true,
                    stages: false,
                    stage_type: true
                },
                unified_stages: {
                    display: true,
                    expanded: 'auto' // [true, false, auto]
                },
                result_images: {
                    win: {
                        female1: require('./dist/img/banner/img_background_result_win_1_1.jpg'),
                        female2: require('./dist/img/banner/img_background_result_win_1_2.jpg'),
                        male1: require('./dist/img/banner/img_background_result_win_2_1.jpg'),
                        male2: require('./dist/img/banner/img_background_result_win_2_2.jpg')
                    },
                    lose: require('./dist/img/banner/img_background_result_lose.jpg')
                }
            },
            scorm: {
                list: 'themes', // [themes / courses]
                categories: {
                    grid: {
                        style: 'default', // [default / card]
                        size: 'small', // [small / large]
                        mobile: 'default', // [default / full]
                    },
                    progress: {
                        enabled: true,
                        type: 'circular', // [bar / circular]
                        label: true,
                        position: 'center' // [left / center / right]
                    }
                },
            },
            exam: {
                navigation: 'top', // [bottom / top]
                score: true
            },
            free: {
                categories: {
                    grid: {
                        style: 'default', // [default / card]
                        size: 'small', // [small / large]
                        mobile: 'default', // [default / full]
                    },
                },
            }
        },
        ranking: {
            flag: {
                enabled: true,
                type: "country", // [country / states]
                categories: {
                    grid: {
                        style: 'default', // [default / card]
                        size: 'small', // [small / large]
                        mobile: 'default', // [default / full]
                    },
                    progress: {
                        enabled: true,
                        type: 'circular', // [bar / circular]
                        label: true,
                        position: 'center' // [left / center / right]
                    }
                },
            },
            filter: true,
            max_positions: 0 // [infinite = 0 / limit number (min = 3)]
        },
        library: {
            have_category: true
        },
        manager: {
            head_cards: {
                scorm: true,
                classroom: true,
                trail: true,
            },
            table_team_employees: true,
            table_team_activities: true,
            table_all_users: true
        },
        my_history: {
            enabled: true,
            head_cards: {
                last_login: true,
                ranking: true,
                courses_obligatory: true,
                courses_completed: true,
            },
            my_activities: {
                enabled: true,
                type_course: true,
                type: true,
                date_start: true,
                date_finish: true,
                workload: true,
                certificate_validity: true,
                do_until: true,
                obligatory: true
            }
        },
        faq: {
            expanded: true
        },
        settings: {
            edit_profile: {
                avatar: {
                    enabled: true
                },
                first_name: {
                    enabled: false,
                    required: false
                },
                last_name: {
                    enabled: false,
                    required: false
                }
            }
        }
    },
}

const loadTheme = (project) => {
    const options = projects[project]
    if (!options) throw new Error(`Project ${project} not found!`)
    const theme = require(`./components/Themes/${options.app.name_project.toLowerCase()}`).default

    return {
        ...options,
        theme,
    }
}

export default loadTheme(process.env.REACT_APP_QR_PROJECT)
