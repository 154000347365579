import React, { forwardRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MobileBottomNavigation } from './QrMenu';
import { Slide } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DialogBadgeDetails from '../scenes/Achievements/components/DialogBadgeDetails';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const QrContent = ({ children, hideSidebar, id, className, bottomNavigation }) => {
    const theme = useTheme();
    const menuOpen = useSelector(state => state.qrLayout.menuOpen);
    const sidebarOpen = useSelector(state => state.qrLayout.sidebarOpen);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [open, setOpen] = useState(false);
    const [badgeQueue, setBadgeQueue] = useState([]);
    const [currentBadge, setCurrentBadge] = useState([]);

    const handleClose = () => {
        setOpen(false);

        // Atualiza o localStorage ao fechar o dialog
        const updatedBadges = badgeQueue.slice(1);
        localStorage.setItem('badges_unlocked', JSON.stringify(updatedBadges.length > 0 ? updatedBadges : null));
    };

    useEffect(() => {
        const badgesUnlocked = JSON.parse(localStorage.getItem('badges_unlocked'));

        if (badgesUnlocked) {
            setBadgeQueue(Array.isArray(badgesUnlocked) ? badgesUnlocked : [badgesUnlocked]);
        }
    }, []);

    useEffect(() => {
        if (!open && badgeQueue.length > 0) {
            const nextBadge = badgeQueue[0];
            setCurrentBadge(nextBadge);
            setBadgeQueue(prevQueue => prevQueue.slice(1));
            setOpen(true);
        }
    }, [open, badgeQueue]);

    return (
        <div id="qr-middle" className={`sb__${hideSidebar ? 'hidden' : sidebarOpen ? 'open' : 'close'} menu__${menuOpen ? 'open' : 'close'}`}>
            <main id={id} className={`qr-main ${className || ""}`} role="main">
                <div className="qr-main_content">
                    {children}
                    {isMobile && bottomNavigation && <MobileBottomNavigation />}
                </div>
            </main>
            <DialogBadgeDetails
                open={open}
                close={handleClose}
                data={currentBadge}
                transition={Transition}
            />
        </div>
    );
};

export default QrContent;
