import { achievementsService } from "../../services/achievements.service";

// CONST
export const Types = {
    LIST: 'achievements/LIST',
    REDEEM: 'achievements/REDEEM',
    UPDATE: 'achievements/UPDATE',
    RESET: 'achievements/RESET',
};

// INITIAL STATE
const initialState = {
    list: {},
    redeem: {},
}

// REDUCER
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.LIST:
            return {
                ...state,
                list: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.REDEEM:
            return {
                ...state,
                redeem: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.UPDATE:
            return {
                ...state,
                list: {
                    ...state.list, // preserva todas as propriedades atuais de list
                    data: {
                        ...state.list.data, // preserva as propriedades existentes em data
                        total_user: action.payload.total_user, // substitui total_user
                    },
                },
            }
        case Types.RESET:
            return {
                ...state,
                list: {},
                redeem: {},
            }
        default:
            return state
    }
}

// ACTIONS
function getList(params) {
    return dispatch => {
        return achievementsService.getList(params).then(data => {
            dispatch({
                type: Types.LIST,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.LIST,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function setRedeem(params) {
    return dispatch => {
        return achievementsService.setRedeem(params).then(data => {
            dispatch({
                type: Types.REDEEM,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.REDEEM,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function setUpdate(params) {
    return dispatch => {
        dispatch({
            type: Types.UPDATE,
            payload: {
                total_user: params.total_user
            }
        });
    }
}

function reset() {
    return dispatch => {
        dispatch({
            type: Types.RESET
        });
    }
}

export const actions = {
    getList,
    setRedeem,
    setUpdate,
    reset,
}
