import React, { useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import config from '../../../config';
import ThemesList from './themes.list';
import TrailList from './trail.list';
import Details from './details';
import Statistics from './statistics';
import Materials from './materials';
import StepList from './step.list';
import StepIntro from './step.intro';
import StepFlashcards from './step.flashcards';
import StepScorm from './step.scorm';
import StepQuestions from './step.questions';
import StepQuestionsSolution from './step.questions.solution';
import StepResult from './step.result';
import StageComplete from './stage.complete';
import Survey from '../../../components/Survey';

export default function Trail(props) {
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const [themeMode] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);

    useEffect(() => {
        if (auth.authenticated === false || (auth.modules.TRAIL && auth.modules.TRAIL.ENABLED === 'N')) {
            history.replace('/');
        }
    }, [auth, history]);

    return (
        <div id="qr-root" className={themeMode === "DARK" ? "__dark" : "__light"}>
            {
                auth.authenticated &&
                <Switch>
                    <Route path={`${props.match.path}`} exact
                        component={
                            (props) =>
                                config.learn.trail.list === "themes" ?
                                    <ThemesList {...props} />
                                    :
                                    <TrailList {...props} />
                        }
                    />

                    <Route path={`${props.match.path}/:idTheme/list`} exact component={
                        (props) => <TrailList {...props} />}
                    />

                    <Route path={`${props.match.path}/:idTrail`} exact component={
                        (props) => <Details {...props} />}
                    />

                    <Route path={`${props.match.path}/:idTrail/statistics`} exact component={
                        (props) => <Statistics {...props} />}
                    />

                    <Route path={`${props.match.path}/:idTrail/survey`} exact component={
                        (props) => <Survey {...props} />}
                    />

                    <Route path={`${props.match.path}/:idTrail/:idStage`} exact component={
                        (props) => <StepList {...props} />}
                    />

                    <Route path={`${props.match.path}/:idTrail/:idStage/materials`} exact component={
                        (props) => <Materials {...props} />}
                    />

                    <Route path={`${props.match.path}/:idTrail/:idStage/intro`} exact component={
                        (props) => <StepIntro {...props} />}
                    />

                    <Route path={`${props.match.path}/:idTrail/:idStage/flashcards`} exact component={
                        (props) => <StepFlashcards {...props} />}
                    />

                    <Route path={`${props.match.path}/:idTrail/:idStage/scorm`} exact component={
                        (props) => <StepScorm {...props} />}
                    />

                    <Route path={`${props.match.path}/:idTrail/:idStage/questions`} exact component={
                        (props) => <StepQuestions {...props} />}
                    />

                    <Route path={`${props.match.path}/:idTrail/:idStage/questions/solution`} exact component={
                        (props) => <StepQuestionsSolution {...props} />}
                    />

                    <Route path={`${props.match.path}/:idTrail/:idStage/result`} exact component={
                        (props) => <StepResult {...props} />}
                    />

                    <Route path={`${props.match.path}/:idTrail/:idStage/complete`} exact component={
                        (props) => <StageComplete {...props} />}
                    />
                </Switch>
            }
        </div>
    )
};
