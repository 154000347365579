import React from 'react';
import ReactDOM from 'react-dom';
import Router from './router/Router';
import {Provider} from 'react-redux';
import {Store} from './redux/stores';
import {ThemeProvider} from '@material-ui/styles';
import project from './config';
import {Helmet} from 'react-helmet';
import {registerServiceWorker} from './serviceWorker';
import {askUserPermission, getMessage, reportNotification} from './firebase';

// console.log('[index.js:11] Iniciando o aplicativo React.');

if (process.env.REACT_APP_QR_PROJECT === 'FLEURY') {
    require(`../src/assets/scss/projects/fleury/style.scss`)
} else if (process.env.REACT_APP_QR_PROJECT === 'QRANIO') {
    require(`../src/assets/scss/projects/qranio/style.scss`)
} else if (process.env.REACT_APP_QR_PROJECT === 'ASSAI') {
    require(`../src/assets/scss/projects/assai/style.scss`)
} else if (process.env.REACT_APP_QR_PROJECT === 'NED') {
    require(`../src/assets/scss/projects/ned/style.scss`)
} else if (process.env.REACT_APP_QR_PROJECT === 'NOVARTIS') {
    require(`../src/assets/scss/projects/novartis/style.scss`)
} else if (process.env.REACT_APP_QR_PROJECT === 'MEUAPP') {
    require(`../src/assets/scss/projects/meuapp/style.scss`)
} else if (process.env.REACT_APP_QR_PROJECT === 'ASTRAZENECA') {
    require(`../src/assets/scss/projects/astrazeneca/style.scss`)
} else if (process.env.REACT_APP_QR_PROJECT === 'YAMAHA') {
    require(`../src/assets/scss/projects/yamaha/style.scss`)
} else if (process.env.REACT_APP_QR_PROJECT === 'INPASA') {
    require(`../src/assets/scss/projects/inpasa/style.scss`)
} else if (process.env.REACT_APP_QR_PROJECT === 'AMIGAO') {
    require(`../src/assets/scss/projects/amigao/style.scss`)
}

window.processName = process.env.REACT_APP_QR_PROJECT

// Renderiza o aplicativo React na DOM
ReactDOM.render(
    <Provider store={Store}>
        <Helmet>
            <html lang="pt-br"/>
            <meta http-equiv="Content-Language" content="pt-br"/>
            <meta charSet="utf-8"/>
            <meta name="mobile-web-app-capable" content="yes"/>
            <title>{project.app.name}</title>
            <link rel="apple-touch-icon" href={process.env.PUBLIC_URL + project.app.favicon}/>
            <link rel="apple-touch-icon" sizes="152x152" href={process.env.PUBLIC_URL + `/favicon/${process.env.REACT_APP_QR_PROJECT.toLowerCase()}/apple-icon-152x152.png`}/>
            <link rel="apple-touch-icon" sizes="180x180" href={process.env.PUBLIC_URL + `/favicon/${process.env.REACT_APP_QR_PROJECT.toLowerCase()}/apple-icon-180x180.png`}/>
            <link rel="apple-touch-icon" sizes="144x144" href={process.env.PUBLIC_URL + `/favicon/${process.env.REACT_APP_QR_PROJECT.toLowerCase()}/apple-icon-144x144.png`}/>
            {/* <!-- iPhone X (1125px x 2436px) --> */}
            <link rel="apple-touch-startup-image" media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)" href={process.env.PUBLIC_URL + `/splashscreens/${process.env.REACT_APP_QR_PROJECT.toLowerCase()}/iphonex_splash.png`}/>
            {/* <!-- iPhone 8, 7, 6s, 6 (750px x 1334px) --> */}
            <link rel="apple-touch-startup-image" media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)" href={process.env.PUBLIC_URL + `/splashscreens/${process.env.REACT_APP_QR_PROJECT.toLowerCase()}/iphone6_splash.png`}/>
            {/* <!-- iPhone 8 Plus, 7 Plus, 6s Plus, 6 Plus (1242px x 2208px) --> */}
            <link rel="apple-touch-startup-image" media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3)" href={process.env.PUBLIC_URL + `/splashscreens/${process.env.REACT_APP_QR_PROJECT.toLowerCase()}/iphoneplus_splash.png`}/>
            {/* <!-- iPhone 5 (640px x 1136px) --> */}
            <link rel="apple-touch-startup-image" media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)" href={process.env.PUBLIC_URL + `/splashscreens/${process.env.REACT_APP_QR_PROJECT.toLowerCase()}/iphone5_splash.png`}/>
            {/* <!-- iPad Mini, Air (1536px x 2048px) --> */}
            <link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)" href={process.env.PUBLIC_URL + `/splashscreens/${process.env.REACT_APP_QR_PROJECT.toLowerCase()}/ipad_splash.png`}/>
            {/* <!-- iPad Pro 10.5" (1668px x 2224px) --> */}
            <link rel="apple-touch-startup-image" media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)" href={process.env.PUBLIC_URL + `/splashscreens/${process.env.REACT_APP_QR_PROJECT.toLowerCase()}/ipadpro1_splash.png`}/>
            {/* <!-- iPad Pro 12.9" (2048px x 2732px) --> */}
            <link rel="apple-touch-startup-image" media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)" href={process.env.PUBLIC_URL + `/splashscreens/${process.env.REACT_APP_QR_PROJECT.toLowerCase()}/ipadpro2_splash.png`}/>
            <link rel="shortcut icon" href={process.env.PUBLIC_URL + project.app.favicon}/>
            <link rel="manifest" href={process.env.PUBLIC_URL + "/manifest-" + process.env.REACT_APP_QR_PROJECT.toLowerCase() + ".json"}/>
            <script src="/js/broadcastchannel-polyfill.js"></script>
            <body className="new-class-for-body">
            </body>
        </Helmet>
        <ThemeProvider theme={project.theme}>
            <Router/>
        </ThemeProvider>
    </Provider>, document.getElementById('root'))

// console.log('[index.js:48] Aplicativo React renderizado com sucesso.');

// Registra o Service Worker do Firebase Messaging e envia as configurações do Firebase para ele
navigator.serviceWorker
    .register('firebase-messaging-sw.js')
    .then((registration) => {
        if (registration.active) {
            // Envia as configurações do Firebase para o Service Worker
            registration.active.postMessage({
                type: 'SETUP_FIREBASE',
                config: project.firebase.config, // Passa as configurações do Firebase para o Service Worker
            });
            // console.log('[index.js:58] Configurações do Firebase enviadas para o service worker:', project.firebase.config);
        }
    })
    .catch((error) => {
        // console.error('[index.js:62] Falha ao registrar o Service Worker:', error);
    });

// Solicita permissões de notificação e gerencia mensagens
// console.log('[index.js:66] Solicitando permissão de notificação.');
askUserPermission()
    .then(() => {
        //console.log('[index.js:68] Permissão de notificação concedida.');
    })
    .catch((error) => {
        //console.error('[index.js:70] Erro ao solicitar permissão de notificação:', error);
    });

// console.log('[index.js:73] Configurando listener para mensagens em primeiro plano.');
getMessage();

// console.log('[index.js:76] Registrando eventos de notificação no Analytics.');
reportNotification();

// console.log('[index.js:79] Registrando o Service Worker principal.');
registerServiceWorker();
