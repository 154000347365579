import { qrService } from './base.service';

function getList(params) {
    return qrService('/v1/app/badge/list/user', params)
}

function setRedeem(params) {
    return qrService('/v1/app/badge/redeem', params);
}

export const achievementsService = {
    getList,
    setRedeem,
}
