import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from "../../config";
import countries from 'i18n-iso-countries';
import useReactRouter from 'use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actions as ProfileActions } from '../../redux/ducks/profile';
import { imageParser, numberSocialMask, numberMask } from '../../utils/utils';
import {
    AppBar, Toolbar, Container, Typography, Grid,
    IconButton, useMediaQuery, Avatar, Tooltip
} from '@material-ui/core';
import { QrMenu } from '../../components/QrMenu';
import QrContent from '../../components/QrContent';
import SelectAvatar from '../../components/SelectAvatar';
import Cropper from '../../components/Cropper/Cropper';
import ErrorContent from '../../components/ErrorContent';
import CardTrailChart from './components/CardTrailChart';
import CardClassroomChart from './components/CardClassroomChart';
import CardScormChart from './components/CardScormChart';
import CardPerformanceChart from './components/CardPerformanceChart';
import { LoadingContent } from '../../components/Loading';
import { Icon, Flag, Points } from '../../components/Images/Images';

// Countries
countries.registerLocale(require("i18n-iso-countries/langs/pt.json"));
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/es.json"));

export default function Profile(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const { profile } = useSelector(state => state.profile);
    const isScreenUpMd = useMediaQuery(theme => theme.breakpoints.up('md'));
    const [themeMode] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);
    const [didMount, setDidMount] = useState(false);
    const [openCrop, setOpenCrop] = useState(false);
    const [cropImage, setCropImg] = useState({
        imageSrc: null,
        crop: { x: 0, y: 0 },
        zoom: 1,
    })
    let bottomMenuItem = config.layout.menu.nav_bottom_order.includes('MENU_PROFILE');
    let isLoggedUser = props.match.params.id === auth.user.id_user ? true : false;

    useEffect(() => {
        if (auth.authenticated === false) {
            history.replace('/');
        }
    }, [auth, history]);

    useEffect(() => {
        setDidMount(true);

        if (didMount) {

            let params = {}
            if (!isLoggedUser) {
                params = {
                    id_user_request: props.match.params.id
                }
            }
            dispatch(ProfileActions.getProfile(params));

            if (auth.modules.TRAIL && auth.modules.TRAIL.ENABLED === 'Y') {
                dispatch(ProfileActions.getTrailStats(params));
            }

            if (auth.modules.CLASSROOM_COURSE && auth.modules.CLASSROOM_COURSE.ENABLED === 'Y') {
                dispatch(ProfileActions.getClassroomStats(params));
            }

            if (auth.modules.SCORM && auth.modules.SCORM.ENABLED === 'Y') {
                dispatch(ProfileActions.getScormStats(params));
            }

            if (auth.modules.FREE && auth.modules.FREE.ENABLED === 'Y') {
                dispatch(ProfileActions.getPerformanceStats(params));
            }
        }

        return () => {
            setDidMount(false);
            dispatch(ProfileActions.reset());
        }
    }, [didMount, dispatch, props.match, auth, isLoggedUser]);

    function imgHandler(imageDataUrl) {
        setCropImg({
            imageSrc: imageDataUrl,
            crop: { x: 0, y: 0 },
            zoom: 1,
        });
        setOpenCrop(true);
    }

    const QrAppBar = () => {
        return (
            <AppBar className={`appbar ${profile.error ? "appbar-default" : "appbar-overlay"}`} position={`${profile.error ? "sticky" : "absolute"}`} color="primary">
                <Toolbar>
                    {
                        (!bottomMenuItem && !isScreenUpMd) || !isLoggedUser ?
                            <IconButton
                                color="inherit"
                                aria-label="back"
                                edge="start"
                                onClick={() => history.goBack()}
                            >
                                <Icon ic="back" />
                            </IconButton>
                            :
                            null
                    }
                </Toolbar>
            </AppBar>
        )
    }

    const ProfileInfo = () => {
        return (
            <Grid id="profile-info" container justify="center">
                <Container maxWidth="md" className="pb-4">
                    <Grid container justify="center" spacing={1}>
                        <Grid item xs={12} align="center">
                            {
                                isLoggedUser ?
                                    <>
                                        <SelectAvatar alt={profile.data.fname} blob={profile.data.image ? profile.data.image : null} imageDataUrl={(img) => imgHandler(img)} />
                                        {
                                            openCrop && <Cropper img={cropImage.imageSrc} closeModal={(closeModal) => {
                                                setOpenCrop(closeModal)
                                                setCropImg({})
                                            }} />
                                        }
                                    </>
                                    :
                                    <Avatar
                                        alt={profile.data.fname}
                                        src={profile.data.image ? imageParser(profile.data.image) : "/img/img_avatar_user.png"}
                                        className="avatar avatar-xl avatar-bordered-lg avatar-bordered-profile"
                                    />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h2" component="h2">
                                {profile.data.fname} {profile.data.lname}
                            </Typography>
                        </Grid>
                        {
                            profile.data.country ?
                                <Grid item xs={12}>
                                    <Grid container direction="row" alignItems="center" justify="center">
                                        <Flag type="country" code={profile.data.country.toLowerCase()} className="img-xxs" />
                                        <Typography variant="body2" component="p" className="fs-md font-weight-bold ml-1">
                                            {countries.getName(profile.data.country, 'pt')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                :
                                null
                        }
                        {
                            profile.data.information ?
                                <>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" alignItems="center" justify="center">
                                            <Typography variant="body2" component="p">
                                                {profile.data.information.role_name}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>
                                :
                                null
                        }
                    </Grid>
                </Container>
                <Grid container id="profile-info-points">
                    <Container maxWidth="md">
                        <Grid container justify="center">
                            <Grid item xs={12} sm={8} xl={10}>
                                <Grid container alignItems="center" justify="space-around">
                                    <Grid item xs className="d-flex justify-content-center">
                                        <Tooltip title={`${intl.get("LABEL_XP")}: ${numberMask(profile.data.total_points)}`} placement="top" arrow>
                                            <div className="info-points-content">
                                                <Points type="xp" className="img-md" />
                                                <div className="ml-2">
                                                    <Typography variant="caption" component="p">
                                                        {intl.get('LABEL_XP')}
                                                    </Typography>
                                                    <Typography variant="body1" className="text-xp" component="p">
                                                        {numberSocialMask(profile.data.total_points)}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </Tooltip>
                                    </Grid>
                                    {
                                        config.layout.points.qis ?
                                            <Grid item xs className="d-flex justify-content-center">
                                                <Tooltip title={`${intl.get("LABEL_QIS")}: ${numberMask(profile.data.qi_credit)}`} placement="top" arrow>
                                                    <div className="info-points-content">
                                                        <Points type="qis" className="img-md" />
                                                        <div className="ml-2">
                                                            <Typography variant="caption" component="p">
                                                                {intl.get('LABEL_QIS')}
                                                            </Typography>
                                                            <Typography variant="body1" className="text-qis" component="p">
                                                                {numberSocialMask(profile.data.qi_credit)}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                            </Grid>
                                            : null
                                    }
                                    {
                                        config.layout.points.neuros ?
                                            <Grid item xs className="d-flex justify-content-center">
                                                <Tooltip title={`${intl.get("LABEL_NEUROS")}: ${numberMask(profile.data.neuros)}`} placement="top" arrow>
                                                    <div className="info-points-content">
                                                        <Points type="neuros" className="img-md" />
                                                        <div className="ml-2">
                                                            <Typography variant="caption" component="p">
                                                                {intl.get('LABEL_NEUROS')}
                                                            </Typography>
                                                            <Typography variant="body1" className="text-neuros" component="p">
                                                                {numberSocialMask(profile.data.neuros)}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                            </Grid>
                                            : null
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
        )
    }

    const ProfileContent = () => {
        return (
            <Container id="profile-content" maxWidth="lg">
                <Grid container alignItems="flex-start" alignContent="stretch" justify="flex-start" spacing={2}>
                    {
                        auth.modules.TRAIL && auth.modules.TRAIL.ENABLED === 'Y'
                            ?
                            <Grid item xs={12} sm>
                                <CardTrailChart isLoggedUser={isLoggedUser} name={`${profile.data.fname} ${profile.data.lname}`} />
                            </Grid>
                            : null
                    }
                    {
                        auth.modules.SCORM && auth.modules.SCORM.ENABLED === 'Y'
                            ?
                            <Grid item xs={12} sm>
                                <CardScormChart isLoggedUser={isLoggedUser} name={`${profile.data.fname} ${profile.data.lname}`} />
                            </Grid>
                            : null
                    }
                    {
                        auth.modules.CLASSROOM_COURSE && auth.modules.CLASSROOM_COURSE.ENABLED === 'Y'
                            ?
                            <Grid item xs={12} sm>
                                <CardClassroomChart isLoggedUser={isLoggedUser} name={`${profile.data.fname} ${profile.data.lname}`} />
                            </Grid>
                            : null
                    }
                    {
                        auth.modules.FREE && auth.modules.FREE.ENABLED === 'Y'
                            ?
                            <Grid item xs={12} sm>
                                <CardPerformanceChart isLoggedUser={isLoggedUser} name={`${profile.data.fname} ${profile.data.lname}`} />
                            </Grid>
                            : null
                    }
                </Grid>
            </Container>
        )
    }

    return (
        <div id="qr-root" className={themeMode === "DARK" ? "__dark" : "__light"}>
            {
                auth.authenticated &&
                <div id="qr-wrap">
                    <QrMenu />
                    <div id="qr-content">
                        <QrContent id="profile" hideSidebar>
                            {
                                profile && profile.load === "finished" ?
                                    !profile.error ?
                                        <div>
                                            {
                                                config.layout.navbar ?
                                                    <QrAppBar />
                                                    : null
                                            }
                                            <div id="profile-cover"></div>

                                            {/* Profile info */}
                                            <ProfileInfo />

                                            {/* Content */}
                                            <ProfileContent />
                                        </div>
                                        :
                                        <div>
                                            {
                                                config.layout.navbar ?
                                                    <QrAppBar />
                                                    : null
                                            }
                                            <ErrorContent />
                                        </div>
                                    :
                                    <LoadingContent />
                            }
                        </QrContent>
                    </div>
                </div>
            }
        </div>
    )
}
