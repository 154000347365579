import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import useReactRouter from 'use-react-router';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { actions as SettingsActions } from '../../redux/ducks/settings';
import { actions as AuthActions } from '../../redux/ducks/auth';
import { imageParser } from '../../utils/utils';
import {
    List, ListItem, ListItemIcon, ListItemText,
    ListItemAvatar, ListSubheader, ListItemSecondaryAction,
    FormControl, Select, MenuItem, Switch, Avatar,
    Divider, useMediaQuery, AppBar, Toolbar,
    IconButton, Typography, Container, Collapse
} from '@material-ui/core';
import { QrMenu } from '../../components/QrMenu';
import QrContent from '../../components/QrContent';
import SnackBar from '../../components/snackBar';
import { Icon } from '../../components/Images/Images';

const ListItemLink = React.forwardRef((props, ref) => (
    <NavLink {...props} activeClassName="selected" innerRef={ref} exact />
));

export default function SettingsMenu(props) {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const collapseOpen = useSelector(state => state.settings.collapseOpen);
    const { history } = useReactRouter();
    const [open, setOpen] = useState(collapseOpen);
    const [clicked, setClicked] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [update, setUpdate] = useState(false);
    const [updateStatus, setUpdateStatus] = useState('error');
    const [updateMessage, setUpdateMessage] = useState(intl.get('SETTINGS_PREFERENCES_UPDATE_ERROR'));
    const [theme, setTheme] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);
    const [pushNotifications, setPushNotifications] = useState(auth.user.enable_push ? auth.user.enable_push : 'Y');
    //const [sfx, setSfx] = useState(auth.user.enable_sfx ? auth.user.enable_sfx : 'Y');
    const [news, setNews] = useState(auth.user.enable_news ? auth.user.enable_news : 'Y');
    const [notifications, setNotifications] = useState(auth.user.enable_notifications ? auth.user.enable_notifications : 'Y');
    const [openDataRedeem, setOpenDataRedeem] = useState(false);
    //const [music, setMusic] = useState(auth.user.enable_music ? auth.user.enable_music : 'Y');
    const isScreenUpMd = useMediaQuery(theme => theme.breakpoints.up('md'));
    let bottomMenuItem = config.layout.menu.nav_bottom_order.includes('MENU_SETTINGS');
    var modules = {};

    if (localStorage.getItem('auth.state.modules') && localStorage.getItem('auth.state.modules').length > 0) {
        modules = JSON.parse(localStorage.getItem('auth.state.modules'));
    }

    useEffect(() => {
        if (clicked) {
            dispatch(SettingsActions.collapseIsOpen());
            setClicked(false);
            setOpen(collapseOpen)
        }

        /* if (auth.preferencesStatus && auth.preferencesStatus === "success") {
            auth.preferencesStatus = '';
            setUpdateStatus('success');
            setSnackOpen(true);
            setUpdateMessage(intl.get('SETTINGS_PREFERENCES_UPDATE_SUCCESS'));
        } */

        if (auth.preferencesStatus && auth.preferencesStatus === "error") {
            auth.preferencesStatus = '';
            setUpdateStatus('error');
            setSnackOpen(true);
            setUpdateMessage(intl.get('SETTINGS_PREFERENCES_UPDATE_ERROR'));
        }
    }, [collapseOpen, clicked, open, auth, dispatch]);

    useEffect(() => {
        let didMount = true;

        const changePreferences = () => {
            if (didMount && update) {
                dispatch(AuthActions.updatePreferences({
                    id_user: auth.user.id_user,
                    enable_push: pushNotifications,
                    //enable_sfx: sfx,
                    enable_news: news,
                    enable_notifications: notifications,
                    //enable_music: music,
                    layout_theme: theme,
                }));
                setUpdate(false);
            }
        };

        changePreferences();

        return () => didMount = false;

    }, [dispatch, update, auth, news, notifications, theme, pushNotifications]);

    const handleChangeTheme = (e) => {
        setTheme(e.target.value);
        setUpdate(true);
    };

    const handleChangePush = (e) => {
        setPushNotifications(e.target.checked ? "Y" : "N");
        setUpdate(true);
    };

    const handleChangeNotifications = (e) => {
        setNotifications(e.target.checked ? "Y" : "N");
        setUpdate(true);
    };

    const handleChangeNews = (e) => {
        setNews(e.target.checked ? "Y" : "N");
        setUpdate(true);
    };

    /* const handleChangeMusic = (e) => {
        setMusic(e.target.checked ? "Y" : "N");
        setUpdate(true);
    };

    const handleChangeSfx = (e) => {
        setSfx(e.target.checked ? "Y" : "N");
        setUpdate(true);
    }; */

    const QrAppBar = () => {
        return (
            <AppBar className={`appbar appbar-${bottomMenuItem || isScreenUpMd ? "main" : "default"}`} position={`${bottomMenuItem || isScreenUpMd ? "static" : "sticky"}`} color="primary">
                <Toolbar>
                    {
                        !bottomMenuItem && !isScreenUpMd ?
                            <IconButton
                                color="inherit"
                                aria-label="back"
                                edge="start"
                                onClick={() => history.goBack()}
                            >
                                <Icon ic="back" />
                            </IconButton>
                            :
                            null
                    }
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get('SETTINGS_TITLE')}
                        </Typography>
                        {
                            bottomMenuItem || isScreenUpMd ?
                                <div className="w-100 d-flex overflow-hidden">
                                    <Typography variant="body2" noWrap>
                                        {intl.get("SETTINGS_SUBTITLE")}
                                    </Typography>
                                </div>
                                : null
                        }
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    const MenuList = () => {
        return (
            <div>
                <List component="nav">
                    {/* EDITAR PERFIL */}
                    <ListItem button component={ListItemLink} to="/settings/edit-profile">
                        <ListItemAvatar>
                            <Avatar alt={auth.user.fname} src={auth.user.image ? imageParser(auth.user.image) : "https://b.qranio.com/avatar"} className="avatar-bordered" />
                        </ListItemAvatar>
                        <ListItemText primary={intl.get('SETTINGS_EDIT_PROFILE_TITLE')} secondary={auth.user.fname} />
                    </ListItem>

                    {
                        // ALTERAR SENHA
                        modules && modules.SETTINGS && modules.SETTINGS.CHANGE_PASSWORD === 'Y' ?
                            <ListItem button component={ListItemLink} to="/settings/change-password">
                                <ListItemIcon>
                                    <Icon ic="key" color="light" />
                                </ListItemIcon>
                                <ListItemText primary={intl.get('SETTINGS_CHANGE_PASSWORD_TITLE')} />
                            </ListItem>
                            : null
                    }

                    {
                        // DADOS PARA RESGATE
                        modules && modules.SETTINGS && modules.SETTINGS.REDEEM_INFO === 'Y' ?
                            <>
                                <ListItem button onClick={() => setOpenDataRedeem(!openDataRedeem)}>
                                    <ListItemIcon>
                                        <Icon ic="address" color="light" />
                                    </ListItemIcon>
                                    <ListItemText primary={intl.get('SETTINGS_DATA_REDEEM_TITLE')} />
                                    {
                                        openDataRedeem ?
                                            <Icon ic="chevron_up" color="light" className="icon-xxxs" />
                                            :
                                            <Icon ic="chevron_down" color="light" className="icon-xxxs" />
                                    }
                                </ListItem>
                                <Collapse in={openDataRedeem}>
                                    <ListItem button component={ListItemLink} to="/settings/data-redeem/info" className="collapse-item">
                                        <ListItemText primary={intl.get('SETTINGS_DATA_REDEEM_INFO_TITLE')} />
                                    </ListItem>
                                    <ListItem button component={ListItemLink} to="/settings/data-redeem/address" className="collapse-item">
                                        <ListItemText primary={intl.get('SETTINGS_DATA_REDEEM_ADDRESS_TITLE')} />
                                    </ListItem>
                                </Collapse>
                            </>
                            : null
                    }
                </List>
                <Divider />
                <List subheader={<ListSubheader className="list-subheader">{intl.get('SETTINGS_PREFERENCES_TITLE')}</ListSubheader>}>
                    <ListItem>
                        <ListItemText primary={intl.get('SETTINGS_PREFERENCES_THEME_TITLE')} />
                        <ListItemSecondaryAction>
                            <FormControl>
                                <Select
                                    labelId="settings-preferences-theme-label"
                                    id="settings-preferences-theme-select"
                                    value={theme}
                                    onChange={handleChangeTheme}
                                >
                                    <MenuItem value="LIGHT">{intl.get('LABEL_LIGHT')}</MenuItem>
                                    <MenuItem value="DARK">{intl.get('LABEL_DARK')}</MenuItem>
                                </Select>
                            </FormControl>
                        </ListItemSecondaryAction>
                    </ListItem>
                    {
                        modules && modules.SETTINGS && modules.SETTINGS.PHONE_NOTIFICATION === 'Y' ?
                            <ListItem>
                                <ListItemText primary={intl.get('SETTINGS_PREFERENCES_PUSH_NOTIFICATIONS_TITLE')} />
                                <ListItemSecondaryAction>
                                    <Switch
                                        name="push-notifications"
                                        color="primary"
                                        checked={pushNotifications === "Y" ? true : false}
                                        onChange={handleChangePush}
                                        inputProps={{ 'aria-label': 'push-notifications' }}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                            : null
                    }
                    {
                        modules && modules.SETTINGS && modules.SETTINGS.EMAIL_NOTIFICATION === 'Y' ?
                            <ListItem>
                                <ListItemText primary={intl.get('SETTINGS_PREFERENCES_NOTIFICATIONS_TITLE')} />
                                <ListItemSecondaryAction>
                                    <Switch
                                        name="notifications"
                                        color="primary"
                                        checked={notifications === "Y" ? true : false}
                                        onChange={handleChangeNotifications}
                                        inputProps={{ 'aria-label': 'notifications' }}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                            : null
                    }
                    {
                        modules && modules.SETTINGS && modules.SETTINGS.NEWSLETTER === 'Y' ?
                            <ListItem>
                                <ListItemText primary={intl.get('SETTINGS_PREFERENCES_NEWS_TITLE')} />
                                <ListItemSecondaryAction>
                                    <Switch
                                        name="news"
                                        color="primary"
                                        checked={news === "Y" ? true : false}
                                        onChange={handleChangeNews}
                                        inputProps={{ 'aria-label': 'news' }}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                            : null
                    }
                    {/* <ListItem disableGutters>
                                <ListItemText primary={intl.get('SETTINGS_PREFERENCES_MUSIC_TITLE')} />
                                <ListItemSecondaryAction>
                                    <Switch
                                        color="primary"
                                        checked={music === "Y" ? true : false}
                                        onChange={handleChangeMusic}
                                        name="music"
                                        inputProps={{ 'aria-label': 'music' }}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem disableGutters>
                                <ListItemText primary={intl.get('SETTINGS_PREFERENCES_SFX_TITLE')} />
                                <ListItemSecondaryAction>
                                    <Switch
                                        color="primary"
                                        checked={sfx === "Y" ? true : false}
                                        onChange={handleChangeSfx}
                                        name="SFX"
                                        inputProps={{ 'aria-label': 'SFX' }}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                             */}
                </List>
            </div>
        )
    }

    return (
        <div id="qr-wrap">
            <QrMenu />
            <div id="qr-content">
                <QrContent id="setings" hideSidebar>
                    {
                        config.layout.navbar ?
                            <QrAppBar />
                            : null
                    }
                    <Container maxWidth="md" className="container-list pt-2 pb-4">
                        <MenuList />
                    </Container>
                </QrContent>
                <SnackBar
                    open={snackOpen}
                    message={updateMessage}
                    status={updateStatus}
                    time={3}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    closeSnack={() => setSnackOpen(false)}
                />
            </div>
        </div>
    )
}
